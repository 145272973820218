<div class="d-flex justify-content-between">
    <div class="heatmap-body">
        <div class="heatmap-canvas-body mt-2">
            <div class="heatmap-people-count">
                <div class="d-flex justify-content-center analytics-map px-2">            
                    <div #peopleCountHeatMapChart id="peopleCountHeatMapChart" class="draw-canvas" style="width: 1920px; height: 1080px;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="heatmap-analytics">
        <div class="people-count p-4 text-center my-2">
            <p>Time Duration: <span><strong>6AM to 9PM</strong></span></p>
            <h4>Total Indoor People Count</h4>
            <div class="d-flex justify-content-center text-center">
                <h2>{{peopleCountValue}}</h2>
            </div>
        </div>
        <!-- <div class="people-count-by-hour">
            <div class="people-count p-2 my-2">
                <p>ID by Time of Day</p>
                <div id="peopleCountBarChart" style="width: 100%; height: 400px;"></div>
            </div> 
        </div> -->
    </div>
</div>