import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridModule } from 'ag-grid-angular';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CommonService } from 'src/app/services/common.service';
import { AgCellActionsComponent } from './ag-cell-actions/ag-cell-actions.component';
import jwt_decode from "jwt-decode";
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-org-grid',
  templateUrl: './org-grid.component.html',
  styleUrls: ['./org-grid.component.scss']
})
export class OrgGridComponent implements OnInit {

  @Input('rowData') org: any[];
  @Output() gridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  private gridApi: any;

  constructor(private commonService: CommonService, 
    private configuartionService: ConfigurationService,
    private authorizationService: AuthorizationService ) { 
    this.commonService.orgGridActionEvent.subscribe((data) => {
      this.gridActionEvent.emit(data);
    });
  }

  pagination = true;
  paginationPageSize = 10;
  suppressPaginationPanel = true;
  paginationPageSizeSelector: boolean = false;
  pageSize: number = this.configuartionService.itemsPerPage;
  rowHeight: number = 50;

  public defaultColDef: ColDef = {
    sortable: true,
    flex: 1,
    // resizable: true,
  };
  colDefs: ColDef[] = [];
  decodedToken: any;

  ngOnInit(): void {
    this.initializeColumnDef();
  }

  initializeColumnDef() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.colDefs = [
      {
        headerName: 'Organization Name',
        field: 'tenantName',
        minWidth: 195,
      },
      {
        headerName: 'City',
        field: 'cityName',
        minWidth: 150,
      },
      {
        headerName: 'State',
        field: 'stateName',
        minWidth: 150,
      },
      {
        headerName: 'Country',
        field: 'countryName',
        minWidth: 100,
      },
      {
        headerName: 'Email',
        field: 'orgEmail1',
        minWidth: 180,
        sortable: false,
      },
      {
        headerName: 'Contact Number',
        field: 'orgPhoneNo1',
        minWidth: 180,
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params: { value: boolean; }) => {
          const statusText = params.value ? 'Active' : 'Inactive';
          const statusClass = params.value ? 'ag-cell-active' : 'ag-cell-inactive';
          return `<span class="${statusClass}">${statusText}</span>`;
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 120,
      },
      {
        headerName: 'Actions',
        cellClass: 'ag-cell-actions',
        cellRenderer: AgCellActionsComponent,
        cellRendererParams: {
          gridIdentifier: 'org',
          isEditAllowed: this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['update', '*']),
          isDeleteAllowed: this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['delete', '*'])
        },
        minWidth: 100,
        sortable: false,
        resizable: false,
      },
    ];
  }

  onGridReady(params: { api: any }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

}