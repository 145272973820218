<div class="p-3 row m-0">
  <div class="store-search-box px-0">
    <div class="card search-box card-box">
      <div class="d-flex br-3 justify-content-between px-3">
        <div class="search-item d-flex me-4 py-3">
          <label>Country</label>
          <div class="search-selector">
            <select [disabled]="!isCountriesAllowed" class="store-search" (change)="getStatesList($event)" [(ngModel)]="defaultCountry">
              <option selected value="select">- Select -</option>
              <option *ngFor="let country of countriesList | orderBy:'country.countryName'"
                value="{{country.countryUID}}">
                {{country.countryName}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>State</label>
          <div class="search-selector">
            <select [disabled]="!isStatesAllowed"class="store-search" (change)="getZipCodesList($event)" [(ngModel)]="defaultState">
              <option selected value="select">- Select -</option>
              <option *ngFor="let state of statesList | orderBy:'state.stateName'" value="{{state.stateUID}}">
                {{state.stateName}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>Zip Code</label>
          <div class="search-selector">
            <select [disabled]="!isZipCodeAllowed" class="store-search" (change)="getStoresList($event)" [(ngModel)]="defaultZip">
              <option selected value="select">- Select -</option>
              <option *ngFor="let zip of zipCodesList | orderBy:'name'">{{zip.zipCode}}
              </option>
            </select>
          </div>
        </div>

        <div class="search-item d-flex me-4 py-3">
          <label>Store</label>
          <div class="search-selector">
            <select [disabled]="!isStoresAllowed" class="store-search" (change)="fetchStoreData($event)" [(ngModel)]="defaultStore">
              <option selected value="select">- Select -</option>
              <option value="{{store.storeId}}" *ngFor="let store of storesList | orderBy:'storeName'">
                {{store.storeName}}
              </option>
            </select>
          </div>
        </div>

        <div class="widget-customize ms-auto py-3">
          <div class="custom-widget">
            <span><img src="../../../../assets/icons/widgets.svg" alt=""></span>
            Customize Widgets
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="insight-charts mx-0 mt-3 px-0">

    <div class="insight-chart-box widget-1 px-0 heat-map-chart-box" *ngIf="showHeatMapForSpeUser">
      <div class="px-2">
        <div class="chart-header d-flex justify-content-between align-items-center">
          <div>Store Heatmap</div>
          <div class="icon-expand" (click)="openHeatMapPopup()"></div>
        </div>
        <div class="chart-card padding-heatmap">
          <div class="inline-loading-spinner" *ngIf="loading"></div>
          <app-heat-map [heatMapDataSet]="heatMapDataSet" [colorArrayLegends]="colorArrayLegends" [cameraData]="cameraData" 
            [selectedCamera]="selectedCamera" [heatMapCameraImage]="heatMapCameraImage"
            [storeName]="storeName" [currentTime]="currentTime" (selectedCameraValue)="selectedCameraValue($event)"></app-heat-map>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-1 px-0" *ngIf="isStorePosDisplay">
      <div class="px-2">
        <div class="chart-header">
          POS Sales
        </div>
        <div class="chart-card p-3">
          <div class="daily-price mb-3">
            <h3>$1,302,410</h3>
            <p>Daily POS $</p>
          </div>
          <div class="d-flex mb-3">
            <div class="dual-box">
              <h3>$8,8MM</h3>
              <p>WTD POS $</p>
            </div>
            <div class="dual-box">
              <h3>$8,8MM</h3>
              <p>MTD POS $</p>
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between">
            <div class="value-levels">
              <img src="../../../../assets/icons/increase-arrow.svg" alt="">
              <h4>+2.1%</h4>
              <p>Vs LY</p>
            </div>
            <div class="value-levels">
              <img src="../../../../assets/icons/decrease-arrow.svg" alt="">
              <h4>-3.4%</h4>
              <p>Vs D-1</p>
            </div>
            <div class="value-levels">
              <img src="../../../../assets/icons/increase-arrow.svg" alt="">
              <h4>+1.6%</h4>
              <p>LY for WTD</p>
            </div>
            <div class="value-levels">
              <img src="../../../../assets/icons/increase-arrow.svg" alt="">
              <h4>+5.8%</h4>
              <p>LY for MTD</p>
            </div>
          </div>

          <div class="d-flex">
            <div class="dual-box hours">
              <h3>8h 00 - 10h21</h3>
              <p>POS $ Peak Hour</p>
            </div>
            <div class="dual-box hours">
              <h3>Sat 05.20.23</h3>
              <p>POS $ Peak Day</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-1 px-0" *ngIf="isStoreAlertsDisplay">
      <div class="px-2">
        <div class="chart-header">
          Alerts
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeAlertsChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeAlertsChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p>
                <span *ngIf="storeAlertsChartData?.resolution_time !== null">{{storeAlertsChartData?.resolution_time |
                  secondsToMinutes}}</span>
                <span *ngIf="storeAlertsChartData?.resolution_time === null">0s</span> Avg Resolution Time
              </p>
            </div>
          </div>

          <div class="donut-chart-info chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Today's Alerts</h5>
              <ul class="chart-labels">
                <li *ngFor="let item of storeAlertsChartData?.today_alert"><span class="level-1"></span> {{item.name}} -
                  {{item.value}}
                </li>
              </ul>
            </div>
            <div class="data-chart">
              <div echarts [options]="storeAlertsPieChart" class="demo-chart"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Alerts distributed by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>    
              <div echarts [options]="storeAlertsBarChart" class="demo-chart"></div>
            </ng-template>  
            <ng-template carouselSlide *ngIf="storeAlertActivehours">            
              <div echarts [options]="storeAlertsBarChart1" class="demo-chart"></div>
            </ng-template> 
          </owl-carousel-o>
            </div>
          </div>

          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top {{storeAlertsChartData?.top.length}}</h4>
              <ul>
                <li *ngFor="let item of storeAlertsChartData?.top, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
            <div class="bottom-list-items">
              <h4>Bottom {{storeAlertsChartData?.bottom.length}}</h4>
              <ul>
                <li *ngFor="let item of storeAlertsChartData?.bottom, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="insight-chart-box widget-2 px-0" *ngIf="isUCExists('OOS') && isStoreOosDisplay">
      <div class="px-2">
        <div class="chart-header">
          In-Stock Compliance
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeOosChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeOosChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p><span *ngIf="storeOosChartData?.resolution_time !== null">{{storeOosChartData?.resolution_time |
                  secondsToMinutes}}</span><span *ngIf="storeOosChartData?.resolution_time === null">0s</span> Avg
                Resolution Time</p>
            </div>
          </div>

          <div class="stock-compliance chart-widget-box py-2 px-3">
            <div class="data-chart instock-chart">
              <div echarts [options]="storeOosPieChart" class="demo-chart"></div>
              <div class="instock-left">
                <p>${{storeOosChartSavedValue | number:'1.0-0'}}<small>{{storeOosChartSaved}}</small></p>
              </div>
              <div class="instock-right">
                <p>${{storeOosChartAtRiskValue | number:'1.0-0'}}<small>{{storeOosChartAtRisk}}</small></p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Alerts distributed by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>            
                  <div echarts [options]="storeOosBarChart" class="demo-chart"></div>
                </ng-template>  
                <ng-template carouselSlide  *ngIf="storeOosActiveHours">    
                  <div echarts [options]="storeOosBarChart1" class="demo-chart"></div>
                </ng-template>  
              </owl-carousel-o>
            </div>
          </div>
          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top <span *ngIf="storeFootfallChartData?.top?.length > 0">{{storeFootfallChartData?.top?.length +
                  storeFootfallChartData?.bottom?.length}}</span></h4>
              <ul>
                <li *ngFor="let listData of storeOosChartData?.top, let i = index">{{i + 1}}. {{listData}}</li>
              </ul>
            </div>
            <div class="bottom-list-items" style="padding-top: 28px;">
              <ul>
                <li *ngFor="let listData of storeOosChartData?.bottom, let i = index">{{i + 4}}. {{listData}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-2 px-0" *ngIf="isStoreProtectionDisplay">
      <div class="px-2">
        <div class="chart-header">
          Asset Protection
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeProtectionChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeProtectionChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p><span
                  *ngIf="storeProtectionChartData?.resolution_time !== null">{{storeProtectionChartData?.resolution_time
                  | secondsToMinutes}}</span><span *ngIf="storeProtectionChartData?.resolution_time === null">0s</span>
                Avg Resolution Time</p>
            </div>
          </div>

          <div class="stock-compliance chart-widget-box py-2 px-3">
            <div class="data-chart instock-chart">
              <div echarts [options]="storeProtectionPieChart" class="demo-chart"></div>
              <div class="instock-left">
                <p>${{storeProtectionChartSavedValue | number:'1.0-0'}}<small>{{storeProtectionChartSaved}}</small></p>
              </div>
              <div class="instock-right">
                <p>${{storeProtectionChartAtRiskValue | number:'1.0-0'}}<small>{{storeProtectionChartAtRisk}}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Alerts distributed by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide >            
                  <div echarts [options]="storeProtectionBarChart" class="demo-chart"></div>
                </ng-template>  
                <ng-template carouselSlide *ngIf="storeProtectionActiveHours">    
                  <div echarts [options]="storeProtectionBarChart1" class="demo-chart"></div>
                </ng-template>  
              </owl-carousel-o>
            </div>
          </div>
          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top {{storeProtectionChartData?.top.length}}</h4>
              <ul>
                <li *ngFor="let listData of storeProtectionChartData?.top, let i = index">{{i + 1}}. {{listData}}</li>
              </ul>
            </div>
            <div class="bottom-list-items">
              <h4>Bottom {{storeProtectionChartData?.bottom.length}}</h4>
              <ul>
                <li *ngFor="let listData of storeProtectionChartData?.bottom, let i = index">{{i + 1}}. {{listData}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="insight-chart-box widget-3 px-0" *ngIf="isStoreSelfCheckoutDisplay">
      <div class="px-2">
        <div class="chart-header">
          Self Checkout
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeSelfCheckoutChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeSelfCheckoutChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p><span
                  *ngIf="storeSelfCheckoutChartData?.resolution_time !== null">{{storeSelfCheckoutChartData?.resolution_time
                  | secondsToMinutes}}</span><span
                  *ngIf="storeSelfCheckoutChartData?.resolution_time === null">0s</span>
                Avg Resolution Time</p>
            </div>
          </div>

          <div class="stock-compliance chart-widget-box py-2 px-3">
            <div class="data-chart instock-chart">
              <div echarts [options]="storeSelfCheckoutPieChart" class="demo-chart"></div>
              <div class="instock-left">
                <p>${{storeSelfCheckoutSavedValue | number:'1.0-0'}}<small>{{storeSelfCheckoutSaved}}</small></p>
              </div>
              <div class="instock-right">
                <p>${{storeSelfCheckoutAtRiskValue | number:'1.0-0'}}<small>{{storeSelfCheckoutAtRisk}}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="alert-distributed chart-widget-box self-checkout py-2 px-3">
            <div class="scan-info d-flex justify-content-center">
              <p class="mis-scan" *ngFor="let title of storeSelfCheckoutChartDataLables"><span></span> {{title.name}}
              </p>
            </div>

            <div class="data-chart">
              <div echarts [options]="storeSelfCheckoutLineChart" class="demo-chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-3 px-0" *ngIf="isStoreLoiteringDisplay">
      <div class="px-2">
        <div class="chart-header">
          Loitering
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeLoiteringChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeLoiteringChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p><span
                  *ngIf="storeLoiteringChartData?.resolution_time !== null">{{storeLoiteringChartData?.resolution_time |
                  secondsToMinutes}}</span><span *ngIf="storeLoiteringChartData?.resolution_time === null">0s</span> Avg
                Resolution Time</p>
            </div>
          </div>


          <div class="donut-chart-info chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Cases Created</h5>
              <ul class="chart-labels">
                <li *ngFor="let item of storeLoiteringChartData?.cases"><span class="level-1"></span> {{item.name}} -
                  {{item.value}}</li>
              </ul>
            </div>
            <div class="data-chart">
              <div echarts [options]="storeLoiteringPieChart" class="demo-chart"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Alerts distributed by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>            
                  <div echarts [options]="storeLoiteringBarChart" class="demo-chart"></div>
                </ng-template>  
                <ng-template carouselSlide *ngIf="storeLoiteringActiveHours">    
                  <div echarts [options]="storeLoiteringBarChart1" class="demo-chart"></div>
                </ng-template>  
              </owl-carousel-o>
            </div>
          </div>
          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top {{storeLoiteringChartData?.top.length}}</h4>
              <ul>
                <li *ngFor="let item of storeLoiteringChartData?.top, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
            <div class="bottom-list-items">
              <h4>Bottom {{storeLoiteringChartData?.bottom.length}}</h4>
              <ul>
                <li *ngFor="let item of storeLoiteringChartData?.bottom, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-1 px-0" *ngIf="isUCExists('peoplecounter') && isStoreFootfallDisplay">
      <div class="px-2">
        <div class="chart-header">
          Customer Footfall
        </div>
        <div class="chart-card">
          <div class="foodfall-area px-2">
            <div class="foodfall-area-left">
              <h4>{{storeFootfallChartData?.walk_in_today}}</h4>
              <p>Customers <br />In Store today</p>
            </div>
            <div class="foodfall-area-right py-2">
              <h4>{{storeFootfallChartData?.walk_in_total}}</h4>
              <p>Total Customers<br /> during NRF</p>
            </div>
          </div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Customers in the Store by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>            
                  <div echarts [options]="storeFootfallBarChart" class="demo-chart"></div>
                </ng-template>  
                <ng-template carouselSlide *ngIf="storeFootfallActiveHours">    
                  <div echarts [options]="storeFootfallBarChart1" class="demo-chart"></div>
                </ng-template>  
              </owl-carousel-o>
            </div>
          </div>

          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Footfalls By Day</h5>
            </div>
            <div class="data-chart">
              <div echarts [options]="storeFootfallLineChart" class="demo-chart"></div>
            </div>
          </div>

          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top {{storeFootfallChartData?.top.length}}</h4>
              <ul>
                <li *ngFor="let listData of storeFootfallChartData?.top, let i = index">{{i + 1}}. {{listData}}</li>
              </ul>
            </div>
            <div class="bottom-list-items">
              <h4>Bottom {{storeFootfallChartData?.bottom.length}}</h4>
              <ul>
                <li *ngFor="let listData of storeFootfallChartData?.bottom, let i = index">{{i + 1}}. {{listData}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="insight-chart-box widget-2 px-0" *ngIf="isUCExists('dwell') && isStoreAssistanceDisplay">
      <div class="px-2">
        <div class="chart-header">
          Customer Assistance
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeAssistanceChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeAssistanceChartData?.alerts_complete}}</span> Alerts Completed</p>

              <p>
                <!-- <span
                  *ngIf="storeAssistanceChartData?.resolution_time !== null">{{storeAssistanceChartData?.resolution_time
                  | secondsToMinutes}}</span> -->
                <span *ngIf="storeAssistanceChartData?.resolution_time === null">50s</span>
                Avg Resolution Time
              </p>
            </div>
          </div>

          <div class="customer-assistance chart-widget-box py-2 px-3">
            <div class="d-flex mb-3 mt-3">
              <div class="dual-box">
                <h3>{{storeAssistanceChartData?.dwell | secondsToMinutes}}</h3>
                <p>Avg Dwell Time</p>
              </div>
              <div class="dual-box">
                <h3>{{storeAssistanceChartData?.time_customer | secondsToMinutes}}</h3>
                <p>Avg Time with Customer</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Alerts distributed by hour</h5>
            </div>
            <div class="data-chart">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>            
                  <div echarts [options]="storeAssistanceBarChart" class="demo-chart"></div>
                </ng-template>  
                <ng-template carouselSlide  *ngIf="moreActveHours">    
                  <div echarts [options]="storeAssistanceBarChart1" class="demo-chart"></div>
                </ng-template>  
              </owl-carousel-o>
            </div>
          </div>
          <div class="bottom-list">
            <div class="bottom-list-items">
              <h4>Top {{storeAssistanceChartData?.top.length}}</h4>
              <ul>
                <li *ngFor="let item of storeAssistanceChartData?.top, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
            <div class="bottom-list-items">
              <h4>Bottom {{storeAssistanceChartData?.bottom.length}}</h4>
              <ul>
                <li *ngFor="let item of storeAssistanceChartData?.bottom, let i = index">{{i + 1}}. {{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insight-chart-box widget-3 px-0" *ngIf="isUCExists('customerreception') && isStoreReceptionDisplay">
      <div class="px-2">
        <div class="chart-header">
          Customer Reception
        </div>
        <div class="chart-card p-0">
          <div class="alert-area py-2 px-2 d-flex">
            <div class="alert-price-left py-2 d-flex justify-content-center">
              <img src="../../../../assets/icons/alert.svg" alt="alert" />
              <h3>{{storeReceptionChartData?.alert_sent}}</h3>
              <p>Alerts <br /> Sent</p>
            </div>
            <div class="alert-price-right text-center py-2">
              <p><span>{{storeReceptionChartData?.alerts_complete}}</span> Alerts Completed</p>
              <p><span
                  *ngIf="storeReceptionChartData?.resolution_time !== null">{{storeReceptionChartData?.resolution_time |
                  secondsToMinutes}}</span><span *ngIf="storeReceptionChartData?.resolution_time === null">0s</span> Avg
                Resolution Time</p>
            </div>
          </div>

          <div class="donut-chart-info chart-widget-box py-2 px-3">
            <div class="chart-info">
              <h5>Cases Created</h5>
              <ul class="chart-labels">
                <li *ngFor="let item of storeReceptionChartData?.cases"><span class="level-1"></span> {{item.name}} -
                  {{item.value}}</li>
              </ul>
            </div>
            <div class="data-chart">
              <div echarts [options]="storeReceptionPieChart" class="demo-chart"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="alert-distributed chart-widget-box self-checkout py-2 px-3">
            <div class="chart-info mb-2">
              <h5>Alerts distributed by Time</h5>
            </div>
            <div class="scan-info d-flex justify-content-between">
              <p class="mis-scan" *ngFor="let title of storeReceptLineChartTitles"><span></span>
                {{title?.name}}
              </p>

            </div>
            <div class="data-chart">
              <div echarts [options]="storeReceptionLineChart" class="demo-chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>