<div class="overview-setup">
  <div class="d-flex justify-content-end tab-content addOrg-btn">
    <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
      <option value='Active' selected>Active</option>
      <option value='Inactive'>Inactive</option>
    </select>

    <button class="btn btn-add" *ngIf="isCreateAllowed" (click)="addOrgData()">+ Add Organization</button>
  </div>
</div>

<div class="ag-grid">
  <app-org-grid *ngIf="isCSAdmin" [rowData]="filteredOrgs" (gridActionEvent)="handleActionEvent($event)"></app-org-grid>
</div> 

