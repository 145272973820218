import { Component, OnInit } from "@angular/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { AlertMessagesService } from "src/app/services/alert-messages.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  isSubmitted: Boolean = false;
  error: Boolean = false;
  email: string;
  errorMessage: boolean = false;
  processing: boolean = false;
  providedErrorMessage: string;

  forgotForm: FormGroup = new FormGroup({
    email: new FormControl("", Validators.required),
  });
  get fc() {
    return this.forgotForm.controls;
  }

  constructor(
    private router: Router,
    private configService: ConfigurationService,
    public activeModal: NgbActiveModal,
    private alertMessagesService: AlertMessagesService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.isSubmitted = true;
    this.processing = true;
    setTimeout(() => {
      this.processing = false;
    }, 5000);
    this.configService.forgotPassword(this.forgotForm.value).subscribe(
      async (resp: any) => {
        this.processing = false;
        if (resp.status === true) {
          this.activeModal.close();
          const response = await this.alertMessagesService.alertWithSuccessConfirmAction("If this is a valid account, you should receive the password reset email.");            
          if ( response == true ) {
            this.router.navigate(["/login"]);
          }
        }
        else{
          this.errorMessage = true;
          this.providedErrorMessage = resp.detail;
        }
      },
      (error) => {
        this.errorMessage = true;
        this.providedErrorMessage = error.error;
      }
    );
  }
}
