<div class="d-flex justify-content-end">
  <select class="selected-item" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
    <option value='Active' selected>Active</option>
    <option value='Inactive'>Inactive</option>
  </select>
  
  <select class="selected-item" (change)="changeOrg($event)" *ngIf="isCSAdmin">
    <option value="" [selected]="!selectedTenantIdDropdown">All Organizations</option>
    <option *ngFor="let org of filteredOrgs" value={{org.tenantId}} [selected]="org.tenantId === selectedTenantId">{{org.tenantName}}</option>
  </select>

  <select class="selected-item" (change)="changeStore($event)">
    <option [selected]="!selectedStoreIdDropdown" value="" selected>All Stores</option>
    <option *ngFor="let store of filteredStores" value={{store.storeId}} [selected]="store.storeId === selectedStoreId">{{store.storeName}}</option>
  </select>
  <button class="btn btn-add add-alert-btn" *ngIf="isCreateAllowed" (click)="addAlertData()">
    + Add Alert
  </button>
</div>

<div class="ag-grid">
  <app-useralert-grid [rowData]="useCaseAlert" (gridActionEvent)="handleActionEvent($event)"></app-useralert-grid>
</div> 
<!-- <div class="table-scroller">
  <table class="table alert-table mt-3 datatable" data-pagination="true" data-page-list="[10, 25, 50, 100]"
    data-search="true" data-show-refresh="true">
    <thead>
      <tr class="table-scroller">
        <th>Store Name</th>
        <th>Usecase Name</th>
        <th>Alert Mode</th>
        <th>Priority</th>
        <th>Recipient</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-row no-data" *ngIf="totalItems === 0">
        <td colspan="7" *ngIf="visibleOrg">Please Select The Organization</td>
         <td colspan="7" *ngIf="visibleStore">Please Select The Store</td> 
        <td colspan="7" *ngIf="noDataFound">No Alerts Found</td>
      </tr>
      <tr class="table-row" *ngFor="
            let alert of useCaseAlert
              | paginate
                : {
                    id: paginationId,
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: totalItems
                  }
          ">
        <td>{{ alert.storeName }}</td>
        <td>{{ alert.useCaseName }}</td>
        <td>{{ alert.alertIdentifier}}</td>
        <td><span *ngIf="alert.order === 1">Level 1</span><span *ngIf="alert.order === 2">Level 2</span><span
            *ngIf="alert.order === 3">Level 3</span></td>
        <td>{{ alert.usernames}}</td>
        <td>
          <span class="active" *ngIf="alert.status === true">Active</span>
          <span class="inactive" *ngIf="alert.status === false">Inactive</span>
        </td>
        <td>
          <button class="btn-icon edit-icon" (click)="updateAlertData(alert)"><img
              src="../../../../../assets/icons/editing.svg" alt="" /></button>
          <button class="btn-icon delete-icon" (click)="deleteAlert(alert.alertUcUID, alert.uniqueId)"
            [disabled]="alert.status === false"><img src="../../../../../assets/icons/delete.svg" alt="" /></button>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls (pageChange)="pageChanged($event)" [id]="paginationId" [maxSize]="10" [directionLinks]="true"
    [responsive]="true" [autoHide]="true" *ngIf="totalItems > 0"></pagination-controls>
</div> -->