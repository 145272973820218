import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import * as echarts from "echarts/core";
import { SankeyChart } from "echarts/charts";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { Router } from "@angular/router";
import dayjs from 'dayjs/esm';

interface Link {
  source: string;
  target: string;
  date: string; // Assuming date is in string format
}

// Register the required components
echarts.use([TitleComponent, TooltipComponent, SankeyChart, CanvasRenderer]);

@Component({
  selector: "app-pathway",
  templateUrl: "./pathway.component.html",
  styleUrls: ["./pathway.component.scss"],
})
export class PathwayComponent implements OnInit, AfterViewInit {
  @ViewChild("pathwayChart") pathwayChartRef: ElementRef;
  @Input() public dashboardWidget: any;
  
  pathwayDataSet: any;
  pathwayChart: any;
  selectedDateRange: { startDate: any; endDate: any };
  daterangePicker: boolean = false;
  defaultPathwayDataSet: any;
  pathwayChartOptions: any;
  pathwayWidth: number = 680;
  pathwayHeight: number = 360;
  filtersData: any[] = [];
  highlightedValues: Set<string> = new Set();

  filterTo: string = "";
  filterFrom: string = "";
  noDataFound: boolean = false;
  
  constructor(
    private storeInsightService: StoreInsightService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    this.routerCheck();
    this.pathwayFiltersCheck();
  }

  routerCheck() {
    const currentUrl = this.router.url;
    if (currentUrl.includes("/store-insights")) {
      this.dashboardWidget = true;
    }
  }

  onRangePicker() {
    this.daterangePicker = !this.daterangePicker
  }

  pathwayFiltersCheck() {
    const selectedFilterFrom = localStorage.getItem("pathwayFilterFrom");
    const selectedFilterTo = localStorage.getItem("pathwayFilterTo");
    if (selectedFilterFrom !== null) {
      this.filterFrom = selectedFilterFrom;
    }
    if (selectedFilterTo !== null) {
      this.filterTo = selectedFilterTo;
    }
    this.updateHighlightedValues();
    this.updateChart();
  }

  changeFilterFrom(event: any) {
    if (event.target.value === "") {
      this.filterFrom = "";
    } else {
      this.filterFrom = event.target.value;
    }
    this.updateHighlightedValues();
    this.updateChart();
    localStorage.setItem("pathwayFilterFrom", this.filterFrom);
  }

  changeFilterTo(event: any) {
    if (event.target.value === "") {
      this.filterTo = "";
    } else {
      this.filterTo = event.target.value;
    }
    this.updateHighlightedValues();
    this.updateChart();
    localStorage.setItem("pathwayFilterTo", this.filterTo);
  }

  async ngAfterViewInit(): Promise<void> {
    await this.initializePathwayCanvas();
  }

  async initializePathwayCanvas(): Promise<void> {
    try {
      this.pathwayDataSet = await this.storeInsightService
        .getPathwayDataFromJson()
        .toPromise();
      this.filtersData = this.pathwayDataSet.nodes;
      this.pathwayChart = echarts.init(this.pathwayChartRef.nativeElement);
      this.setPathwayChartOptions();
      this.cdr.detectChanges();
    } catch (error) {
    }
  }

  onDateRangeSelected(event: any): void {
    if (event.startDate && event.endDate) {
      const startDate = dayjs(event.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(event.endDate).format('YYYY-MM-DD');

      this.noDataFound = false;
      this.selectedDateRange = {
        startDate: startDate,
        endDate: endDate
      };
    } else {
      this.noDataFound = true;
    }
    this.setPathwayChartOptions();
  }

  async setPathwayChartOptions(): Promise<void> {
    if (this.pathwayChart && this.pathwayDataSet) {
      let filteredNodes = this.pathwayDataSet.nodes;
      let filteredLinks: Link[] = this.pathwayDataSet.links;

      // Filter nodes and links based on selected filter values
      if (this.filterFrom && this.filterTo) {
        filteredLinks = filteredLinks.filter(
          (link: Link) =>
            link.source === this.filterFrom && link.target === this.filterTo
        );
        filteredNodes = this.getUniqueNodes(filteredLinks);
      }
  

      // Filter by date range if selected
      if (this.selectedDateRange && this.selectedDateRange.startDate && this.selectedDateRange.endDate) {
        const startDate = new Date(this.selectedDateRange.startDate);
        const endDate = new Date(this.selectedDateRange.endDate);

        filteredLinks = filteredLinks.filter((link: Link) => {
          const linkDate = new Date(link.date); // Assuming links have 'date' property
          return !isNaN(linkDate.getTime()) && linkDate >= startDate && linkDate <= endDate;
        });
        filteredNodes = this.getUniqueNodes(filteredLinks);
      }

      if (filteredNodes.length === 0 || filteredLinks.length === 0) {
        this.noDataFound = true;
        filteredNodes = this.pathwayDataSet.nodes;
        filteredLinks = this.pathwayDataSet.links;
      }


      // Set chart options
      this.pathwayChart.setOption({
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          show: true,
        },
        animation: true,
        series: [
          {
            type: "sankey",
            emphasis: {
              focus: "adjacency",
            },
            nodeAlign: "right",
            data: filteredNodes.map((node: any) => ({
              ...node,
              emphasis: { focus: this.highlightedValues.has(node.name) },
            })),
            links: filteredLinks,
            levels: [
              {
                depth: 0,
                itemStyle: {
                  color: "#fbb4ae",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.8,
                },
              },
              {
                depth: 1,
                itemStyle: {
                  color: "#b3cde3",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.8,
                },
              },
              {
                depth: 2,
                itemStyle: {
                  color: "#ccebc5",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.8,
                },
              },
              {
                depth: 3,
                itemStyle: {
                  color: "#decbe4",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.8,
                },
              },
            ],
            lineStyle: {
              curveness: 0.6,
            },
          },
        ],
      });
    }
  }

  updateHighlightedValues() {
    this.highlightedValues.clear();
    if (this.filterFrom) {
      this.highlightedValues.add(this.filterFrom);
    }
    if (this.filterTo) {
      this.highlightedValues.add(this.filterTo);
    }
  }

updateChart() {
  if (!this.noDataFound) { 
    this.clearChart(); 
    this.setPathwayChartOptions();
  }
}

  clearChart() {
    if (this.pathwayChart) {
      this.pathwayChart.clear();
    }
  }

  // Utility function to get unique nodes from links
  getUniqueNodes(links: Link[]): any[] {
    const uniqueNodes: Set<string> = new Set();
    const nodes: any[] = [];

    links.forEach((link) => {
      if (!uniqueNodes.has(link.source)) {
          uniqueNodes.add(link.source);
          nodes.push({ name: link.source });
      }
      if (!uniqueNodes.has(link.target)) {
          uniqueNodes.add(link.target);
          nodes.push({ name: link.target });
      }
  });
  return nodes;
  }



}
