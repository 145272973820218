import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
// import { environment } from "src/environments/environment.dev";
import { environment } from "src/environments/environment.qa";

@Injectable({
  providedIn: "root",
})
export class CaseManagementService {
  // Common Services
  apiUtilityUrl = environment.apiAuthUrl;

  // Pitaya Services
  apiCaseManagementService = environment.pitayaCaseManagementService;
  fileUploadServiceBaseUrl = this.apiUtilityUrl + "upload-utils/";
  BToken: any = "Bearer ";

  constructor(private http: HttpClient) {}

  header = this.getHeaders();
  public getHeaders() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    return headers1;
  }

  getCaseManagementList(pageSize: number, currentPage: number) {
    const url = `${this.apiCaseManagementService}/cases/list?page=${currentPage}&size=${pageSize}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  getCaseDetailsById(id: string) {
    const url = `${this.apiCaseManagementService}/cases/detail/${id}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  updateCaseInfoDetails(payload: any, id: any) {
    const url = `${this.apiCaseManagementService}/cases/update/${id}`;
    return this.http.put(url, payload).pipe(catchError(this.handleError));
  }

  updateCaseInfoTabsDetails(payload: any, id: any) {
    const url = `${this.apiCaseManagementService}/cases/update/${id}/tabs-update`;
    return this.http.put(url, payload).pipe(catchError(this.handleError));
  }

  getCaseStatusList() {
    const url = `${this.apiCaseManagementService}/cases/case-status-list`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  downloadCaseInfo(id: any) {
    const url = `${this.apiCaseManagementService}/cases/download-zip/${id}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  uploadCaseAttachments(formData: any) {
    const url = `${this.fileUploadServiceBaseUrl}/upload-file/`;
    return this.http.post(url, formData).pipe(catchError(this.handleError));
  }

  deleteCaseAttachments(formData: any) {
    const url = `${this.fileUploadServiceBaseUrl}/edit-or-delete-file/`;
    return this.http.post(url, formData).pipe(catchError(this.handleError));
  }

  deleteCaseAttachmentsEntry(payload: any, caseId: string) {
    const url = `${this.apiCaseManagementService}/cases/attachment/delete/${caseId}`;
    return this.http
      .delete(url, { headers: this.header, body: payload })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
    } else if (error.status === 400) {
      // Handle 400 Bad Request errors here
    } else {
    }
    return throwError(error);
  }
}
