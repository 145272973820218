import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ROI } from "../usecase-config/usecase-config.component";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-people-counter",
  templateUrl: "./people-counter.component.html",
  styleUrls: ["./people-counter.component.scss"],
})
export class PeopleCounterComponent implements OnInit {
  @Input() configData: any;
  @Output() updatedUseCase: EventEmitter<void> = new EventEmitter<void>();
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  peopleUseCaseForm: FormGroup;
  selectedChangeAxisValue: string = "Threshold X"; // Default selected Axis Value
  AxisValue: string[] = ["Threshold X", "Threshold Y"];
  isAxisX: boolean = true;
  selectedProductROI: ROI = {
    type: "ProductROI",
    xMin: 0,
    yMin: 0,
    xMax: 0,
    yMax: 0,
  };
  roiConfigurations: ROI[] = [];
  filteredRoiConfigurations: ROI[] = [];
  private roiConfigurationsSubscription: Subscription;
  public roiDimensionsSubscription: Subscription;
  productROIConfig: any;
  personROIConfig: any;
  dimensions: any;
  baseValue: 0;
  selectedUseCaseInfo: any;
  selectedUCConfig: any;
  useCaseConfigId: any;
  useCaseIdentifier: any;
  isChecked: boolean = true;
  uplodedImage: any;

  constructor(
    private fb: FormBuilder,
    private configService: ConfigurationService,
    private commonService: CommonService,
    private alertMessagesService: AlertMessagesService,
    public activeModal: NgbActiveModal
  ) {
    this.peopleUseCaseForm = this.fb.group({
      tenant: ["", Validators.required],
      store: ["", Validators.required],
      camera: [],
      thresholdX: [null, [Validators.required]],
      thresholdY: [null, [Validators.required]],
      deltaMinY: [null, [Validators.required]],
      deltaMaxY: [null, [Validators.required]],
      deltaMinX: [null, [Validators.required]],
      deltaMaxX: [null, [Validators.required]],
      counterAlertThreshold: [null, [Validators.required]],
      cameraId: ["", Validators.required],
      deviceId: ["", Validators.required],
      usecaseId: ["", Validators.required],
      AxisValue: [],
      status: [true],
      inputSource: [""],
      imagepath: ["", Validators.required],
    });
    this.fetchConfigData();
  }
  get fc() {
    return this.peopleUseCaseForm.controls;
  }

  ngOnInit(): void {
    this.selectedUseCaseInfo = this.configData.useCase;
    if (this.selectedUseCaseInfo) {
      this.getUseCaseConfigData();
    }
    this.refreshROIData();
  }

  changeAxis(event: any) {
    if (event.target.value == "Threshold X") {
      this.isAxisX = true;
    } else {
      this.isAxisX = false;
    }
  }

  refreshROIData() {
    this.selectedProductROI.xMin = this.baseValue;
    this.selectedProductROI.yMin = this.baseValue;
    this.selectedProductROI.xMax = this.baseValue;
    this.selectedProductROI.yMax = this.baseValue;
  }

  getUseCaseConfigData() {
    this.useCaseConfigId = this.selectedUseCaseInfo.ucConfigUID;
    this.useCaseIdentifier = this.selectedUseCaseInfo.usecaseIdentifier;
    this.configService
      .getUseCaseConfigData(this.useCaseConfigId, this.useCaseIdentifier)
      .subscribe((resp: any) => {
        this.selectedUCConfig = resp.data;
        this.fetchUseCaseConfigData();
      });
  }

  fetchConfigData() {
    this.roiConfigurationsSubscription = this.configService
      .getROIConfigurations()
      .subscribe((configurations: ROI[]) => {
        this.roiConfigurations = configurations;
        this.filterRoiConfigurations();
      });

    this.roiDimensionsSubscription = this.configService
      .getImageDimensions()
      .subscribe((dimensions: any) => {
        this.dimensions = dimensions;
      });
  }

  filterRoiConfigurations() {
    const modifiedRois = [];
    for (const roi of this.roiConfigurations) {
      modifiedRois.push({
        ...roi,
        xMin: roi.xMin * 2,
        yMin: roi.yMin * 2,
        xMax: roi.xMax * 2,
        yMax: roi.yMax * 2,
      });
    }
    this.filteredRoiConfigurations = modifiedRois;
    this.productROIConfig = this.filteredRoiConfigurations[0];
    this.selectedProductROI.xMin = this.productROIConfig?.xMin;
    this.selectedProductROI.yMin = this.productROIConfig?.yMin;
    this.selectedProductROI.xMax = this.productROIConfig?.xMax;
    this.selectedProductROI.yMax = this.productROIConfig?.yMax;
  }

  fetchUseCaseConfigData() {
    if (this.selectedUCConfig) {
      if (this.selectedUCConfig.settings.ENTRY_AXIS.value.thresholdX) {
        this.selectedChangeAxisValue = "Threshold X"
        this.isAxisX = true;
        this.peopleUseCaseForm.patchValue({
          tenant: this.selectedUCConfig.tenant,
          store: this.selectedUCConfig.store,
          usecaseId: this.selectedUCConfig.usecaseId,
          cameraId: this.selectedUCConfig.cameraId,
          deviceId: this.selectedUCConfig.deviceId,
          inputSource: this.selectedUCConfig.inputSource,
          camera: this.selectedUCConfig.camera,
          thresholdX: this.selectedUCConfig.settings.ENTRY_AXIS.value.thresholdX,
          counterAlertThreshold: this.selectedUCConfig.settings.COUNTER_ALERT_THRESHOLD.value,
          imagepath: this.selectedUCConfig.imagePath,
        })
      } else {
        this.selectedChangeAxisValue = "Threshold Y"
        this.isAxisX = false;
        this.peopleUseCaseForm.patchValue({
          tenant: this.selectedUCConfig.tenant,
          store: this.selectedUCConfig.store,
          usecaseId: this.selectedUCConfig.usecaseId,
          cameraId: this.selectedUCConfig.cameraId,
          deviceId: this.selectedUCConfig.deviceId,
          inputSource: this.selectedUCConfig.inputSource,
          camera: this.selectedUCConfig.camera,
          thresholdY: this.selectedUCConfig.settings.ENTRY_AXIS.value.thresholdY,
          counterAlertThreshold: this.selectedUCConfig.settings.COUNTER_ALERT_THRESHOLD.value,
          imagePath: this.uplodedImage,
        });
      }
      this.selectedProductROI.xMin = this.selectedUCConfig?.settings.DELTA_MIN_X.value;
      this.selectedProductROI.yMin = this.selectedUCConfig?.settings.DELTA_MIN_Y.value;
      this.selectedProductROI.xMax = this.selectedUCConfig?.settings.DELTA_MAX_X.value;
      this.selectedProductROI.yMax = this.selectedUCConfig?.settings.DELTA_MAX_Y.value;
      this.isChecked = this.configData?.useCase?.status;
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (
      this.selectedProductROI.xMin == undefined ||
      this.selectedProductROI.xMax == undefined ||
      this.selectedProductROI.yMin == undefined ||
      this.selectedProductROI.yMax == undefined ||
      this.selectedProductROI.xMin <= 0 ||
      this.selectedProductROI.xMax <= 0 ||
      this.selectedProductROI.yMin <= 0 ||
      this.selectedProductROI.yMax <= 0 ||
      this.peopleUseCaseForm.get("counterAlertThreshold")?.value == undefined ||
      this.peopleUseCaseForm.get("counterAlertThreshold")?.value <= 0
    )
      return;
    if (this.selectedChangeAxisValue == 'Threshold X') {
      if (this.peopleUseCaseForm.get('thresholdX')?.value == undefined ||
        this.peopleUseCaseForm.get('thresholdX')?.value == "") {
        return;
      }
    }
    if (this.selectedChangeAxisValue == 'Threshold Y') {
      if (this.peopleUseCaseForm.get('thresholdY')?.value == undefined ||
        this.peopleUseCaseForm.get('thresholdY')?.value == "") {
        return;
      }
    }
    this.commonService.selectedUsecaseMapImg.subscribe(imagePath => {
      if (imagePath) {
          this.uplodedImage = imagePath;
      }
    });
    if (this.selectedUCConfig) {
      this.peopleUseCaseForm.patchValue({
        tenant: this.configData?.useCase?.tenantId,
        store: this.configData?.useCase?.storeId,
        usecaseId: this.configData?.useCase?.usecaseId,
        cameraId: this.selectedUCConfig.cameraId,
        deviceId: this.selectedUCConfig.deviceId,
        inputSource: this.selectedUCConfig.inputSource,
        imagepath: this.uplodedImage,
        camera: this.selectedUCConfig.camera,
        status: this.isChecked,
        deltaMinX: this.selectedProductROI.xMin,
        deltaMaxX: this.selectedProductROI.xMax,
        deltaMinY: this.selectedProductROI.yMin,
        deltaMaxY: this.selectedProductROI.yMax,
      });
    } else {
      this.peopleUseCaseForm.patchValue({
        tenant: this.configData?.tenantId,
        store: this.configData?.storeId,
        usecaseId: this.configData?.useCaseUId,
        cameraId: this.configData?.camUId,
        deviceId: this.configData?.deviceId,
        inputSource: this.configData.rtsp,
        imagepath: this.uplodedImage,
        camera: this.configData.camMacAdd,
        status: this.isChecked,
        deltaMinX: this.selectedProductROI.xMin,
        deltaMaxX: this.selectedProductROI.xMax,
        deltaMinY: this.selectedProductROI.yMin,
        deltaMaxY: this.selectedProductROI.yMax,
      });
      this.useCaseIdentifier = this.configData?.useCaseIdentifier;
    }

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
    const ucConfigUID = this.configData?.useCase?.ucConfigUID;
    const useCaseType = this.configData?.useCase?.usecaseIdentifier;
    if (
      !this.selectedProductROI.xMin &&
      !this.selectedProductROI.xMax &&
      !this.selectedProductROI.yMin &&
      !this.selectedProductROI.yMax
    ) {
      this.configService.getFileUploadStatus(true);
    }
    if (ucConfigUID) {
      this.configService
        .updateUseCaseMapping(
          ucConfigUID,
          useCaseType,
          this.peopleUseCaseForm.value
        )
        .subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess( "UseCase Updated successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this.configService
        .createUseCaseMapping(
          this.useCaseIdentifier,
          this.peopleUseCaseForm.value
        )
        .subscribe(
          (resp) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("UseCase Added successfully.");
              this.isLoading = false;
              this.configService.setActiveItem(false);
              this.updatedUseCase.emit();
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
  }

  ngOnDestroy() {
    this.roiConfigurationsSubscription.unsubscribe();
  }
}
