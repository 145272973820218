import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CommonService } from 'src/app/services/common.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AgCellActionsComponent } from '../../organisation/org-grid/ag-cell-actions/ag-cell-actions.component';
import {  Subject, takeUntil } from 'rxjs';
import jwt_decode from "jwt-decode";
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-store-grid',
  templateUrl: './store-grid.component.html',
  styleUrls: ['./store-grid.component.scss']
})
export class StoreGridComponent {
  @Input('rowData') storeData: any[];
  @Output() gridActionEvent: EventEmitter<any> = new EventEmitter<any>();
  private gridApi : any;
  private ngUnsubscribe = new Subject<void>();
  
  constructor(private commonService: CommonService, private configuartionService: ConfigurationService, private authorizationService: AuthorizationService) 
  { 
    this.commonService.storeGridActionEvent.subscribe((data) => {
      this.gridActionEvent.emit(data);
    });
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.gridApi.paginationGoToFirstPage();
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.gridApi.paginationGoToFirstPage();
      }
    });
    
  }
  pagination = true;
  paginationPageSize = 10;
  suppressPaginationPanel = true;
  paginationPageSizeSelector: boolean = false;
  pageSize: number = this.configuartionService.itemsPerPage;
  rowHeight: number = 50;

  public defaultColDef: ColDef = {
    sortable: true,
    flex: 1,
    // resizable: true,
  };  
  colDefs: ColDef[] = [];
  decodedToken: any;

  ngOnInit(): void {
    this.initializeColumnDef();
  }

  initializeColumnDef() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.colDefs = [
      {
        headerName: 'Organization Name',
        field: 'tenantName',
        minWidth: 180,
      },
      {
        headerName: 'Store Name',
        field: 'storeName',
        minWidth: 140,
      },
      {
        headerName: 'City',
        field: 'cityName',
        minWidth: 100,
      },
      {
        headerName: 'State',
        field: 'stateName',
        minWidth: 100,
      },
      {
        headerName: 'Zip Code',
        field: 'zipCode',
        minWidth: 110,
        sortable: false,
      },
      {
        headerName: 'Open Hours',
        field: 'startTime',
        minWidth: 100,
        sortable: false,
      },
      {
        headerName: 'Close Hours',
        field: 'endTime',
        minWidth: 100,
        sortable: false,
      },
      {
        headerName: 'Contact Number',
        field: 'sitePhoneNo1',
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params: { value: boolean; }) => {
          const statusText = params.value ? 'Active' : 'Inactive';
          const statusClass = params.value ? 'ag-cell-active' : 'ag-cell-inactive';
          return `<span class="${statusClass}">${statusText}</span>`;
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 100,
      },
      {
        headerName: 'Actions',
        cellClass: 'ag-cell-actions',
        cellRenderer: AgCellActionsComponent,
        cellRendererParams: {
          gridIdentifier: 'store',
          isStorehypIcon: true,
          isEditAllowed: this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['update', '*']),
          isDeleteAllowed: this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['delete', '*'])
        },
        minWidth: 110,
        sortable: false,
        resizable: false,
      },
    ];
  }

  onGridReady(params: { api: any }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['storeData'] && !changes['storeData'].firstChange) {
      if (this.gridApi) {
        // this.gridApi.paginationGoToFirstPage();
      }
    }
  }
}
