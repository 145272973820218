import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment.dev";
// import { environment } from "src/environments/environment.qa";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiSharedService = environment.pitayaSharedService;
  tasksApiUrl = environment.apiTasksBaseUrl + "tasks/"
  dropdown_url = this.apiSharedService + "/tasks/config/viewtype";
  barchartDataUrl = this.apiSharedService + "/tasks/ident-count/";
  DwellchartDataUrl = this.apiSharedService + "/tasks/dwelltime/";

  BToken: any = "Bearer ";
  constructor(private http: HttpClient) {}

  header = this.getHeaders();
  public getHeaders() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    return headers1;
  }

  // public getTaskData(
  //   pageNumber: number,
  //   useCaseName?: any,
  //   status?: number,
  //   searchItem?: any
  // ): Observable<any> {
  //   const url = `${this.apiSharedService}/tasks/task?pageNumber=${pageNumber}&useCaseName=${useCaseName || ""}&status=${status || ""}&search=${searchItem || ""}`;
  //   return this.http.get(url).pipe(catchError(this.handleError));
  // }

  public getTaskData(
    pageNumber: number,
    useCaseName?: any,
    status?: number,
    searchItem?: any
  ): Observable<any> {
    const url = `${
      this.tasksApiUrl
    }?pageNumber=${pageNumber}&useCaseName=${
      useCaseName || ""
    }&status=${status || ""}&search=${searchItem || ""}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  public getTaskActions(): Observable<any> {
    const url = `${this.apiSharedService}/tasks/gettaskactions`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  public getDuplicateTasks(taskId: string): Observable<any> {
    const url = `${this.tasksApiUrl}getduplicatetasks?taskId=${taskId} `;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  public changeTaskStatus(status: number, taskId: any) {
    const body = { id: taskId, status: status };
    const url = `${this.tasksApiUrl}update-status/`;
    return this.http.patch(url, body).pipe(catchError(this.handleError));
  }

  public updateTaskAction(taskId: number, actionId: number) {
    const body = { id: taskId, actionId: actionId };
    const url = `${this.apiSharedService}/tasks/updatestatus`;
    return this.http.patch(url, body).pipe(catchError(this.handleError));
  }

  public changeTaskReadValue(taskId: number) {
    const body = { task_id: taskId };
    const url = `${this.tasksApiUrl}update-read-status/`;
    return this.http.patch(url, body).pipe(catchError(this.handleError));
  }

  public updateTaskSeverity(taskId: number, severity: number) {
    const body = { task_id: taskId, severity: severity };
    const url = `${this.tasksApiUrl}update-severity/`;
    return this.http.patch(url, body).pipe(catchError(this.handleError));
  }

  public updateTaskFeedback(
    taskId: number,
    actionId: number,
    comments: string,
    reasonText: string
  ) {
    const body = {
      id: taskId,
      actionId: actionId,
      feedbackcomments: comments,
      reasontext: reasonText,
    };
    const url = `${this.apiSharedService}/tasks/updatestatus`;
    return this.http.patch(url, body).pipe(catchError(this.handleError));
  }

  // login(username: any, secret: any) {
  //   const body = { username: username, password: secret };
  //   const url = "https://scoutptrgw.azurewebsites.net/gateway/api/v1/users";
  //   return this.http.post(url, body);
  // }

  public getDropdownOptions(): Observable<any> {
    let headers = new HttpHeaders();
    let headers1 = headers.append("Access-Control-Allow-Origin", "*");
    return this.http
      .get<any[]>(this.dropdown_url, { headers: headers1 })
      .pipe(catchError(this.handleError));
  }

  public getData(selectedValue: string): Observable<any> {
    return this.http
      .get(this.barchartDataUrl + selectedValue)
      .pipe(catchError(this.handleError));
  }

  public getDwellData(selectedValue: string): Observable<any> {
    return this.http
      .get(this.DwellchartDataUrl + selectedValue)
      .pipe(catchError(this.handleError));
  }

  public makeItAcase(taskId: any, cid: any): Observable<any> {
    const data = { task_id: taskId, cid: cid };
    const url = `${this.apiSharedService}/tasks/make-case/${taskId}`;
    return this.http
      .post(url, data, {
        headers: this.header,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
