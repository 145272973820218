import {
  Component,
  OnInit,
  ViewChildren,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddOrganisationComponent } from "./add-organisation/add-organisation.component";

import Swal from "sweetalert2";

import { LayoutService } from "src/app/services/layout.service";
import { DataService } from "../../../../services/search.service";
import { Router, NavigationEnd } from "@angular/router";
import { SessionTimeoutService } from "src/app/services/session-timeout.service";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-organisation-info",
  templateUrl: "./organisation-info.component.html",
  styleUrls: ["./organisation-info.component.scss"],
})
export class OrganisationInfoComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Output() updatedOrgs: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren("tableTop", { read: ElementRef })

  closeResult = "";
  orgData: any;
  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  noDataFound: boolean = false;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  totalItems: number;
  paginationId = "org_items";

  pageTitle: any;
  searchTerm: string = "";
  loggedUser: any;
  filteredData: any[] = [];
  selectedStatus: string = 'Active';
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  decodedToken: any;
  isCreateAllowed: boolean;

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private layoutService: LayoutService,
    private searchService: DataService,
    private router: Router,
    private commonService: CommonService,
    private alertMessagesService: AlertMessagesService,
    private sessionTimeoutService: SessionTimeoutService,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
  }

  ngOnInit(): void {
    this.getPageTitle();
    this.clearSearchField();
    this.configByRole();
    this.sessionTimeoutService.initInactivityTimer();
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCreateAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['create', '*']);
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']) ) {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.filterOrgData();
    } else if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*']) ) {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
    }
  }

  getPageTitle() {
    this.layoutService.pageTitle$.subscribe((pageTitle) => {
      this.pageTitle = pageTitle;
    });
  }
  globalSearch() {
    this.searchService.setSearchValue(this.searchTerm);
    var pageTasks = "/my-tasks";
    var pageStore = "/store-insights";
    var pageStrategic = "/strategy-insights";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url !== pageTasks ||
          event.url !== pageStore ||
          event.url !== pageStrategic
        ) {
          this.searchTerm = "";
          this.searchService.setSearchValue(this.searchTerm);
        } else {
          this.searchService.setSearchValue(this.searchTerm);
        }
      }
    });
  }

  clearSearchField() {
    this.searchService.clearSearchField.subscribe(() => {
      this.searchTerm = "";
      this.globalSearch();
    });
  }

  filterOrgData() {
    this.totalItems = this.filteredOrgs.length;
    if (!this.filteredOrgs) {
      this.noDataFound = true;
    }
  }

  orgPageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  addOrgData() {
    this.modalService
      .open(AddOrganisationComponent, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "add-modal",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.updatedOrgs.emit(this.selectedStatus);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onStatusChange() {
    this.configurationService.getOrgData(this.pageSize, this.currentPage, this.selectedStatus)
      .subscribe((resp: any) => {
        this.orgData = (resp?.data?.length > 0) ? resp.data : [];
        this.filteredOrgs = this.orgData;
      });
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateOrgData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteOrg(actionDetails.data.tenantId);
    }
  }

  updateOrgData(org: any) {
    const modalRef = this.modalService.open(AddOrganisationComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.orgData = org;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.updatedOrgs.emit(this.selectedStatus);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteOrg(tenantId: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Do you want to delete the Organization?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService.deleteOrganisation(tenantId).subscribe(
          (resp: any) => {
            if (resp.message === "Successfully deactivated and deleted") {
              this.alertMessagesService.alertWithSuccess("Organization deleted successfully.");
              this.updatedOrgs.emit(this.selectedStatus);
            } else {
              this.alertMessagesService.alertWithError("Failed to delete Organization");
            }
          },
          (error) => {
            this.alertMessagesService.alertWithError("Failed to delete Organization");
          }
        );
      }
    });
  }
}
