import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError } from "rxjs/operators";
// import { environment } from "src/environments/environment.dev";
import { environment } from "src/environments/environment.qa";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  videoApiUrl = environment.videoApiUrl;

  private currentTimestampSubject = new BehaviorSubject<number>(0);
  currentTimestamp$ = this.currentTimestampSubject.asObservable();

  updateTimestamp(timestamp: number) {
    this.currentTimestampSubject.next(timestamp);
  }

  constructor(private http: HttpClient) {}

  public getTaskVideoData(
    monitorId: string,
    vmsStartTime: any
  ): Observable<any> {
    const apiKey = "pxy6HGQCR6WFbhcIscqjIHSI6NsR4f";
    const groupKey = "4f0df3f2widg4871t0yr2bb60207398c";

    const url = `${this.videoApiUrl}/${apiKey}/videos/${groupKey}/${monitorId}?start=${vmsStartTime}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
