<div class="modal-body modal-heatmap">
    <div class="pathway-popup-container">
      <div class="pathway-header d-flex justify-content-between align-items-center">
        <div>People Pathway</div>
        <div class="icon-minus" (click)="activeModal.dismiss('Cross click')"></div>
      </div>
      <div class="pathway-popup-content pt-4">
        <app-pathway [pathwayDataSet]="pathwayDataSet" [dashboardWidget]="dashboardWidget"
          (storageFiltersFrom)="selectedFilterFrom" (storageFiltersTo)="selectedFilterTo"></app-pathway>
      </div>
      <div class="pathway-footer footer-border"></div>
    </div>
  </div>