import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, HostListener} from '@angular/core';
import * as echarts from "echarts/core";
import { HeatmapChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, VisualMapComponent, GridComponent, GraphicComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { EffectScatterChart } from 'echarts/charts';
import { BarChart } from 'echarts/charts';
import { StoreInsightService } from 'src/app/services/store-insight.service';
// Register the required components
echarts.use([TitleComponent, TooltipComponent, HeatmapChart, VisualMapComponent, GridComponent, GraphicComponent, CanvasRenderer, EffectScatterChart, BarChart]);
interface ROI {
  x_min: number;
  y_min: number;
  x_max: number;
  y_max: number;
}
@Component({
  selector: 'app-heatmap-popup',
  templateUrl: './heatmap-popup.component.html',
  styleUrls: ['./heatmap-popup.component.scss']
})
export class HeatmapPopupComponent implements OnInit, AfterViewInit, OnChanges{
  @ViewChild('peopleCountHeatMapChart') peopleCountHeatMapChart!: ElementRef;
  @Input() activeHeatmapResponse: any;

  defaultCountry: string = "USA_UID";
  defaultState: string = "NewYork_UID";
  defaultZip: string = "100010";
  defaultStore: string = "select";

  countriesList = [
    { countryUID: 'USA_UID', countryName: 'United States' },
  ];
  statesList = [
    { stateUID: 'NewYork_UID', stateName: 'New York' },
  ];
  zipCodesList = [
    { zipCode: '100010' },
  ];

  selectedStores: any = [
    {storeId: "e88edffa-a080-4481-bf1a-45b7c2f69c50", storeName: "Store 8", layoutTitle: "store_8_layout.png"}, 
    {storeId: "ff43fbfe-7606-4dbb-a879-06ba4dcba457", storeName: "Store 9", layoutTitle: "store_9_layout.png"}
  ];

  defaultHeatmapImage: any;
  canvasWidth: any;
  canvasHeight: any;
  imageWidth: number;
  imageHeight: number;
  gridColumns: number = 12;
  gridRows: number = 8;
  activeROIData: any;
  selectedDate: any;
  selectedStore: any;
  activeHeatmapData: any;
  
  constructor(private storeInsightService: StoreInsightService) {}

  ngOnInit(): void {
    if (this.activeHeatmapResponse) {
      this.selectedDate = this.activeHeatmapResponse?.selectedDate;
      this.defaultStore = this.activeHeatmapResponse?.selectedStore;
      this.defaultHeatmapImage = this.activeHeatmapResponse?.storeLayout;
      this.imageWidth = this.activeHeatmapResponse?.imageDimensions?.width;
      this.imageHeight = this.activeHeatmapResponse?.imageDimensions?.height;
      this.canvasWidth = this.activeHeatmapResponse?.imageDimensions?.width;
      this.canvasHeight = this.activeHeatmapResponse?.imageDimensions?.height;
    }
    console.log('activeHeatmapResponse', this.activeHeatmapResponse);
    this.getCustomerHeatmapData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes){
      if (this.activeHeatmapResponse) {
        this.selectedDate = this.activeHeatmapResponse?.selectedDate;
        this.defaultStore = this.activeHeatmapResponse?.selectedStore;
        this.defaultHeatmapImage = this.activeHeatmapResponse?.storeLayout;
        this.imageWidth = this.activeHeatmapResponse?.imageDimensions?.width;
        this.imageHeight = this.activeHeatmapResponse?.imageDimensions?.height;
        this.canvasWidth = this.activeHeatmapResponse?.imageDimensions?.width;
        this.canvasHeight = this.activeHeatmapResponse?.imageDimensions?.height;
      }
      this.getCustomerHeatmapData();
    }
  }

  ngAfterViewInit(): void {
    this.initPathwayCountHeatmapChart();
  }

  datesUpdated(event: any): void {
    this.selectedDate = event.startDate ? event.startDate.format('YYYY-MM-DD') : '2000-01-01';
    if(this.selectedDate) {
      this.getCustomerHeatmapData();
    }
  }

  // Selected store Function
  fetchStoreData(event: any) {
    this.selectedStore = event.target.value;
    this.getCustomerHeatmapData();
    this.defaultStore = this.selectedStore;
  }

  getCustomerHeatmapData() {
    this.storeInsightService.getActiveROIHeatmapInfo(this.defaultStore, this.selectedDate).subscribe((resp: any) => {
      console.log('response in modal', resp);
      if(resp){
        this.activeHeatmapData = resp;
      }
    })
  }

  initPathwayCountHeatmapChart(): void {
    const chartDom = this.peopleCountHeatMapChart.nativeElement;
    const myHeatmapChart = echarts.init(chartDom);
    const activeChartData = this.activeHeatmapData || [];

    if (!Array.isArray(activeChartData)) {
      return; // Stop execution if data is invalid
    }

    const responseData = {
      data: activeChartData.map((item: { coordinates: any[]; unique_count: any; }) => ({
        coordinates: item.coordinates.map(coord => parseFloat(coord.toFixed(2))),
        unique_count: item.unique_count
      }))
    };

    const cellWidth = this.imageWidth / this.gridColumns;
    const cellHeight = this.imageHeight / this.gridRows;

    const heatmapData = responseData.data
    .filter((item: any) => item.unique_count > 0)
    .map((item: any) => {
      const [xStart, xEnd, yStart, yEnd] = item.coordinates;

      // Calculate the center of the coordinates
      const x = (xStart + xEnd) / 2;
      const y = (yStart + yEnd) / 2;

      // Scale the coordinates to fit the canvas (1200x500)
      const scaledX = (x / this.imageWidth) * this.imageWidth;
      const scaledY = (y / this.imageHeight) * this.imageHeight;

      // Use unique_count as the value
      const value = item.unique_count;
      return [scaledX, scaledY, value];
    });
    
    // Generate grid overlay with borders for testing
    const gridLines = [];
    for (let row = 0; row < this.gridRows; row++) {
      for (let col = 0; col < this.gridColumns; col++) {
        const x = col * cellWidth;
        const y = row * cellHeight;
        gridLines.push({
          type: 'rect',
          left: x,
          top: y,
          shape: {
            width: cellWidth,
            height: cellHeight,
          },
          style: {
            stroke: '#a1a1a1',
            fill: 'transparent',
            lineWidth: 1,
          },
          z: -5,
        });
      }
    }
  
    // Configure the heatmap chart options
    const option = {
      tooltip: {},
      grid: {
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '0%',
        containLabel: false,
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: this.imageWidth,
        show: false,
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: this.imageHeight,
        show: false,
      },
      visualMap: {
        min: 0,
        max: 100,
        // left: "20%",
        calculable: true,
        inRange: {
          color: ['#e2614a', '#8e24aa'],
        },
        show: false,
      },
      series: [
        {
          name: 'People Heatmap',
          type: 'effectScatter',
          coordinateSystem: 'cartesian2d',
          data: heatmapData,
          symbolSize: 30,
          showEffectOn: 'render',
          rippleEffect: {
            scale: 2.5,
            brushType: 'stroke',
          },
          encode: {
            tooltip: 2,
          },
          z: 100,
        },
      ],
      graphic: [
        {
          type: 'image',
          left: 'center',
          top: 'center',
          z: -10,
          style: {
            image: this.defaultHeatmapImage,
            width: this.imageWidth,
            height: this.imageHeight,
            opacity: 1,
          },
        },
         ...gridLines, // Add the grid lines to the graphic layer
      ],
    };
  
    // Set the chart options
    myHeatmapChart.setOption(option);
  }
}
