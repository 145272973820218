import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import Swal from "sweetalert2";
import { ROI } from "./usecase-config/usecase-config.component";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import { takeUntil } from 'rxjs/operators';
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { Subject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-usecase",
  templateUrl: "./usecase.component.html",
  styleUrls: ["./usecase.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UsecaseComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];

  roiConfigurations: ROI[] = [];
  private roiConfigurationsSubscription: Subscription;
  closeResult = "";
  deviceData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "useCase_Items";
  selectedTenantName: string = "Centific Admin";
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any = "";
  selectedStoreId: any;
  orgData: any[] = [];
  storeData: any[] = [];
  useCaseData: any[];
  loggedUser: any;

  selectedOrgItem: any = "";
  selectedStoreItem: any = "";

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  visibleOrg: boolean = true;
  visibleStore: boolean = false;
  noDataFound: boolean = false;
  useCaseMapping: boolean = false;
  decodedToken: any;
  isCreateAllowed: boolean;

  selectedTenantIdDropdown: boolean = false;
  selectedStoreIdDropdown: boolean = false;
  selectedStatus: string = 'Active';
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  selectedUseCaseConfig: any;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private alertMessagesService: AlertMessagesService,
    private commonService: CommonService,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.visibleOrg = false;
        this.selectedTenantId = org.tenantId;
        this.selectedStoreId = org.storeId;
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.selectedStoreIdDropdown = true;
        this.selectedStoreId = store.storeId;
      }
    });

    this.commonService.usecaseTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if (isClicked) {
        this.getUseCaseData(
          this.currentPage,
          this.pageSize,
          this.selectedStoreId,
          this.selectedTenantId
        );
      }
    });

    this.roiConfigurationsSubscription = this.configurationService
      .getROIConfigurations()
      .subscribe((configurations: ROI[]) => {
        this.roiConfigurations = configurations;
      });
    this.configurationService.submittedFormData$.subscribe(
      (status: boolean) => {
        this.useCaseMapping = status;
        if (status === false) {
          this.useCaseData = [];
          this.totalItems = 0;
          this.visibleOrg = true;
          if (this.selectedTenantId && this.selectedStoreId) {
            this.getUseCaseData(
              this.currentPage,
              this.pageSize,
              this.selectedStoreId,
              this.selectedTenantId
            );
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.configByRole();
    if (this.selectedStoreId == undefined) {
      this.selectedStoreId = "";
    }
  }

  getUpdatedUsecaseData(): void {
    this.useCaseMapping = false;
    this.getUseCaseData(
      this.currentPage,
      this.pageSize,
      this.selectedStoreId,
      this.selectedTenantId
    );
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCreateAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.['usecase-config'], ['create', '*']);
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']) ) {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.visibleOrg = true;
    } else if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*']) ) {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.visibleOrg = false;
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleOrg = false;
    this.visibleStore = true;
    this.noDataFound = false;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.selectedStoreId = "";
    this.getUseCaseData(
      this.currentPage,
      this.pageSize,
      this.selectedStoreId,
      this.selectedTenantId
    );
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
  }

  getStoreData(tenantId: any) {
    let defaultStatus: string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.visibleOrg = false;
    this.visibleStore = false;
    this.currentPage = 1;
    this.selectedStoreId = event.target.value;
    this.commonService.setSelectedStore({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
    this.getUseCaseData(
      this.currentPage,
      this.pageSize,
      this.selectedStoreId,
      this.selectedTenantId
    );
  }

  onStatusChange() {
    this.configurationService
      .getAllUseCaseConfigData(this.pageSize, this.currentPage, this.selectedStoreId, this.selectedTenantId, this.selectedStatus)
      .subscribe((resp: any) => {
        this.useCaseData = resp.data;
        this.totalItems = resp.data.length;
        if (this.totalItems === 0 && !this.visibleOrg) {
          this.noDataFound = true;
        }
      });
  }

  getUseCaseData(
    currentPage: number,
    pageSize: number,
    storeId: any,
    tenantId: any
  ) {
    this.configurationService
      .getAllUseCaseConfigData(pageSize, currentPage, storeId, tenantId, this.selectedStatus)
      .subscribe((resp: any) => {
        this.useCaseData = resp.data;
        this.totalItems = resp.data.length;
        if (this.totalItems === 0 && !this.visibleOrg) {
          this.noDataFound = true;
        }
      });
  }

  pageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateUsecaseData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteUsecaseData(actionDetails.data.ucConfigUID);
    }
  }

  addUseCase() {
    this.useCaseMapping = true;
    this.selectedUseCaseConfig = {
      isEditing: false,
      filteredOrgs: this.filteredOrgs,
    };
  }

  updateUsecaseData(useCase: any) {
    this.useCaseMapping = true;
    this.selectedUseCaseConfig = {
      useCase: useCase,
      isEditing: true,
    };
    this.getUseCaseData(
      this.currentPage,
      this.pageSize,
      this.selectedStoreId,
      this.selectedTenantId
    );
  }

  dismissUseCaseMapping() {
    this.useCaseMapping = false;
    this.useCaseData = [];
    this.totalItems = 0;
    this.visibleOrg = true;
    this.noDataFound = false;
    this.configByRole();
    this.configurationService.getFileUploadStatus(false);
    if (this.selectedTenantId && this.selectedStoreId) {
      this.selectedOrgItem = this.selectedTenantId;
      this.selectedStoreItem = this.selectedStoreId;
    }
    this.getUseCaseData(
      this.currentPage,
      this.pageSize,
      this.selectedStoreId,
      this.selectedTenantId
    );
  }

  deleteUsecaseData(ucConfigUID: any) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Do you want to delete the Use Case?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService.deleteUsecase(ucConfigUID).subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("Use Case deleted successfully");
              this.getUseCaseData(
                this.currentPage,
                this.pageSize,
                this.selectedStoreId,
                this.selectedTenantId
              );
            } else {
              this.alertMessagesService.alertWithError("Failed to delete Use Case");
            }
          },
          (error) => {
            this.alertMessagesService.alertWithError("Failed to delete Use Case");
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.roiConfigurationsSubscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
