import { Component, OnInit, Input } from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddUserComponent } from "./add-user/add-user.component";
import Swal from "sweetalert2";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthorizationService } from "src/app/services/authorization.service";
import jwt_decode from "jwt-decode";
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];

  storeData: any[] = [];
  // filteredStores: any[] = [];
  noDataFound: boolean = false;
  closeResult = "";
  usersData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  // selectedTenantName: any = "-All Stores-";
  // selectedStoreName: any = "-All Stores-";
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: string = "";
  selectedStoreId: string = "";
  selectedUserId: any;
  paginationId = "users_pagination";
  loggedUser: any;
  visibleStore: boolean = false;
  selectedStatus: string = "Active";
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';

  selectedTenantIdDropdown: boolean = false;
  selectedStoreIdDropdown: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  isCreateAllowed: boolean;
  decodedToken: any;
  isOrgListAllowed: boolean;
  isStoreListAllowed: boolean;

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private alertMessagesService: AlertMessagesService,
    private commonService: CommonService,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.selectedTenantId = org.tenantId;
        this.selectedStoreId = org.storeId;
      }
    });
    this.commonService.selectedStore$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(store => {
      if (store) {
        this.selectedStoreIdDropdown = true;
        this.selectedStoreId = store.storeId;
      }
    });

    this.commonService.userTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if (isClicked) {
        this.getUsersData(this.selectedStoreId, this.selectedTenantId);
      }
    });
  }
  ngOnInit(): void {
    this.configByRole();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCreateAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.user, ['create', '*']);
    this.isOrgListAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']);
    this.isStoreListAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*']);

    if (this.loggedUser === "org-admin") {
      this.selectedTenantId = this.defaultTenantId;
      this.getStoreData(this.selectedTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleStore = true;
    this.noDataFound = false;
    this.selectedTenantId = event.target.value;
    this.selectedStoreId = "";
    this.currentPage = 1;
    if (this.selectedTenantId === "") {
      this.getAllUsersData();
    } else {
      this.getUsersData(this.selectedStoreId, this.selectedTenantId)
    }
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });

  }

  getStoreData(tenantId: any) {
    let defaultStatus: string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.visibleStore = false;
    this.selectedStoreId = event.target.value;
    this.currentPage = 1;
    this.commonService.setSelectedStore({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
    this.getUsersData(this.selectedStoreId, this.selectedTenantId);
  }

  onStatusChange() {
    this.configurationService.getUsersData(this.selectedStoreId, this.selectedTenantId, this.selectedStatus).subscribe((data: any) => {
      this.usersData = data.data;
      this.totalItems = data.data.length;
      if (this.totalItems === 0) {
        this.noDataFound = true;
      }
    });
  }

  getAllUsersData() {

    this.configurationService.getAllUsersData(this.selectedStatus).subscribe((data: any) => {
      this.usersData = data.data;
      this.usersData.forEach((user: any) => {
        user.fullName = `${user.firstName} ${user.lastName}`;
      });
      this.totalItems = data.data.length;
      if (this.totalItems === 0) {
        this.noDataFound = true;
      }
    });
  }

  getUsersData(storeId: any, tenantId: any) {
    this.configurationService.getUsersData(storeId, tenantId, this.selectedStatus).subscribe((data: any) => {
      this.usersData = data.data;
      this.usersData.forEach((user: any) => {
        user.fullName = `${user.firstName} ${user.lastName}`;
      });
      this.totalItems = data.data.length;
      if (this.totalItems === 0) {
        this.noDataFound = true;
      }
    });
  }

  pageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateUserData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteUser(actionDetails.data.userId);
    }
  }

  addUserData() {
    const modelRef = this.modalService.open(AddUserComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        if (this.selectedStoreId === undefined) {
          this.getAllUsersData();
        } else {
          this.getUsersData(this.selectedStoreId, this.selectedTenantId);
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  updateUserData(user: any) {
    const modalRef = this.modalService.open(AddUserComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      keyboard: false,
    });
    modalRef.componentInstance.userData = user;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        if (this.selectedStoreId === undefined) {
          this.getAllUsersData();
        } else {
          this.getUsersData(this.selectedStoreId, this.selectedTenantId);
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteUser(userId: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "Do you want to delete the User?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService.deleteUser(userId).subscribe(
          (resp: any) => {
            if (resp.message === "Successfully deactivated and deleted") {
              this.alertMessagesService.alertWithSuccess("User deleted successfully.");
              if (this.selectedStoreId !== undefined) {
                this.getUsersData(this.selectedStoreId, this.selectedTenantId);
              } else {
                this.getAllUsersData();
              }
            } else {
              this.alertMessagesService.alertWithError("Failed to delete User");
            }
          },
          (error) => {
            this.alertMessagesService.alertWithError("Failed to delete User");
          }
        );
      }     
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
