import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddCameraComponent } from "./add-camera/add-camera.component";
import { CommonService } from "src/app/services/common.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { AuthorizationService } from "src/app/services/authorization.service";
@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.scss", "./../store-setup.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CameraComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];
  @Input() filteredStores: any[] = [];

  isCSAdmin: boolean = false;
  isOrgAdmin: boolean = false;

  closeResult = "";
  cameraData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "camera_pagination";
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any = "";
  selectedStoreId: string = "";
  orgData: any[] = [];
  storeData: any[] = [];
  loggedUser: any;
  decodedToken: any;
  isCreateAllowed: boolean;

  visibleOrg: boolean = false;
  visibleStore: boolean = false;
  noDataFound: boolean = false;
  selectedTenantIdDropdown: boolean = false;
  selectedStoreIdDropdown: boolean = false;
  selectedStatus: string = "Active";
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.visibleOrg = false
        this.selectedTenantId = org.tenantId;
        this.selectedStoreId = org.storeId;       
      }
    });
    this.commonService.selectedStore$.subscribe(store => {
      if (store) {
        this.selectedStoreIdDropdown = true;
        this.selectedStoreId = store.storeId;
      }
    });
    
    this.commonService.cameraTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if(isClicked){
        this.getCameraData(this.selectedStoreId, this.selectedTenantId);
      }
    });
  }

  ngOnInit(): void {
    this.configByRole();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCreateAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.cameras, ['create', '*']);
    if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']) ) {
      this.isCSAdmin = true;
      this.isOrgAdmin = true;
      this.visibleOrg = true;
    } else if ( this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['list', '*']) ) {
      this.isCSAdmin = false;
      this.isOrgAdmin = true;
      this.visibleOrg = false;
      this.getStoreData(this.defaultTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleOrg = false;
    this.visibleStore = true;
    this.noDataFound = false;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.selectedStoreId = "";
    this.getCameraData(this.selectedStoreId, this.selectedTenantId);
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
  }

  getStoreData(tenantId: any) {
    let defaultStatus : string = 'Active';
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, defaultStatus)
      .subscribe((data: any) => {
        this.storeData = data.stores;
        this.storeData = data.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.filteredStores = this.storeData.filter(
          (storeData: any) => storeData.status === true
        );
      });
  }

  changeStore(event: any) {
    this.visibleOrg = false;
    this.visibleStore = false;
    this.currentPage = 1;
    this.selectedStoreId = event.target.value;
    this.commonService.setSelectedStore({
      tenantId: this.selectedTenantId,
      storeId: this.selectedStoreId
    });
    this.getCameraData(this.selectedStoreId, this.selectedTenantId);
  }

  onStatusChange(){
    this.configurationService.getCameraData(this.selectedStoreId, this.selectedTenantId, this.selectedStatus).subscribe((data: any) => {
          this.cameraData = data.data;
          this.totalItems = this.cameraData.length;
          if (this.totalItems === 0) {
            this.noDataFound = true;
          }
        });
  }

  getCameraData(storeId: any, tenantId: any) {
    this.configurationService.getCameraData(storeId, tenantId, this.selectedStatus).subscribe((data: any) => {
      this.cameraData = data.data;
      this.totalItems = this.cameraData.length;
      if (this.totalItems === 0) {
        this.noDataFound = true;
      }
    });
  }

  pageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateCameraData(actionDetails.data);
    }
  }

  addCameraData() {
    const modelRef = this.modalService.open(AddCameraComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getCameraData(this.selectedStoreId, this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  updateCameraData(camera: any) {
    const modalRef = this.modalService.open(AddCameraComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "edit-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.cameraData = camera;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getCameraData(this.selectedStoreId, this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
