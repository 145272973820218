<div class="store-search-box px-0">
  <div class="card search-box card-box">
    <div class="d-flex br-3 justify-content-start px-3">

      <div class="search-item me-4 py-3">
        <label>Country</label>
        <div class="search-selector">
          <select class="store-search" [(ngModel)]="defaultCountry">
            <option value="select">- Select -</option>
            <option *ngFor="let country of countriesList | orderBy:'country.countryName'"
              [value]="country.countryUID">
              {{country.countryName}}
            </option>
          </select>
        </div>
      </div>
      
      <div class="search-item me-4 py-3">
        <label>State</label>
        <div class="search-selector">
          <select class="store-search"[(ngModel)]="defaultState">
            <option value="select">- Select -</option>
            <option *ngFor="let state of statesList | orderBy:'state.stateName'" [value]="state.stateUID">
              {{state.stateName}}
            </option>
          </select>
        </div>
      </div> 

      <div class="search-item me-4 py-3">
        <label>Zip Code</label>
        <div class="search-selector">
          <select class="store-search" [(ngModel)]="defaultZip">
            <option value="select">- Select -</option>
            <option *ngFor="let zip of zipCodesList | orderBy:'name'" [value]="zip.zipCode">
              {{zip.zipCode}}
            </option>
          </select>
        </div>
      </div>

      <div class="search-item me-4 py-3">
        <label>Store</label>
        <div class="search-selector">
          <select class="store-search" (change)="fetchStoreData($event)" [(ngModel)]="defaultStore">
            <option selected value="select">- Select -</option>
            <option value="{{store.storeId}}" *ngFor="let store of selectedStores | orderBy:'storeName'">
              {{store.storeName}}
            </option>
          </select>
        </div>
      </div>

      <div class="search-item me-4 py-3">
        <div class="daterange-filter">
          <label class="heading">Date: </label>
            <div class="daterange-area store-search">
              <input
              ngxDaterangepickerMd
              [closeOnAutoApply]="true"
              [autoApply]="true"
              [singleDatePicker]="true"
              [linkedCalendars]="true"
              [(ngModel)]="selectedDate"
              (datesUpdated)="datesUpdated($event)"
              placeholder="- Select Date -"
              class="single-datepicker-bg">
              <span class="calendar-icon"></span>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between">
  <div class="heatmap-body">
      <div class="heatmap-canvas-body">
          <div class="heatmap-people-count">
              <div class="d-flex justify-content-center analytics-map px-2">            
                  <div #peopleCountHeatMapChart id="peopleCountHeatMapChart" class="draw-canvas" style="width: 1920px; height: 1080px;"></div>
              </div>
          </div>
      </div>
  </div>
</div>