<header class="toolbar">
  <nav class="navbar navbar-expand-lg navbar-light top-nav">
    <div class="container-fluid">
      <h4 class="page-title col-4">{{pageTitle}}</h4>

      <div class="d-flex justify-content-end header-search">
        <div class="selected-zone">Timezone: <span>{{selectedTimezone}}</span></div>
        <div [ngClass]="{'hide-search': searchBarHide == true}">
          <div class="search" *ngIf="searchVisible">
            <input type="text" class="search-input" id="search" disabled placeholder="Search..." name="search"
              [(ngModel)]="searchTerm" (input)="handleInput($event)" (keydown)="handleKeyDown($event)" [minlength]="3"
              autocomplete="off" />
            <button type=" button" class="search-icon"> <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>