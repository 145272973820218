import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StoreInsightService } from "src/app/services/store-insight.service";
import { PathwayComponent } from "../pathway.component";

@Component({
  selector: "app-pathway-container-popup",
  templateUrl: "./pathway-container-popup.component.html",
  styleUrls: ["./pathway-container-popup.component.scss"],
})
export class PathwayContainerPopupComponent implements OnInit {
  @ViewChild(PathwayComponent) pathwayComponent: PathwayComponent;
  @Input() public pathwayDataSet: any;
  @Input() public dashboardWidget: any;

  selectedFilters: any;
  storageFilters: { filterTo: string; filterFrom: string } = {
    filterTo: "",
    filterFrom: "",
  };

  selectedFilterTo: any;
  selectedFilterFrom: any;

  constructor(
    public activeModal: NgbActiveModal,
    public storeInsightService: StoreInsightService
  ) {
    this.pathwayFiltersCheck();
  }

  ngOnInit(): void {}

  pathwayFiltersCheck() {
    const selectedFilterFrom = localStorage.getItem("pathwayFilterFrom");
    const selectedFilterTo = localStorage.getItem("pathwayFilterTo");
    if (this.selectedFilterFrom !== null) {
      this.selectedFilterFrom = selectedFilterFrom;
    }
    if (this.selectedFilterTo !== null) {
      this.selectedFilterTo = selectedFilterTo;
    }
  }
}
