import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Subject, BehaviorSubject } from "rxjs";
import { Observable, timer } from "rxjs";
import { switchMap, map } from "rxjs/operators";

// import { environment } from "src/environments/environment.dev";
import { environment } from "src/environments/environment.qa";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";

const TOKEN_KEY = "auth_token";
const REFRESH_TOKEN = "refresh_token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticated: boolean = false;
  private timeoutHandle: number;
  private readonly timeoutDuration = 1 * 60 * 1000; // 1 minute
  private readonly refreshTokenIntervalMs = 10 * 60 * 1000; // 5 minutes

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.isAuthenticated = !!localStorage.getItem(TOKEN_KEY);
  }

  // Method to check if the user is authenticated
  isAuthenticate(): boolean {
    return this.isAuthenticated;
  }

  apiAuthUrl = environment.apiAuthUrl;
  apiAuthInhouseUrl = environment.apiAuthInHouseUrl;
  apiNotificationUrl = environment.apiNotificationUrl;
  apiKey = environment.pitayaApiKey;

  public getHeaders() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let headers1 = headers.append("X-API-Key", this.apiKey);
    return headers1;
  }
  header = this.getHeaders();

  getAccessToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  setTokens(accessToken: string, refreshToken: string): void {
    this.cookieService.set(TOKEN_KEY, accessToken);
    this.cookieService.set(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    this.startRefreshTokenJob();
  }

  checkAndStartRefreshTokenJob(): void {
    const storedRefreshToken = localStorage.getItem("refresh_token");
    if (storedRefreshToken) {
      this.startRefreshTokenJob();
    }
  }

  navigateUser() {
    try {
      const accessToken = localStorage.getItem("auth_token") ?? "default";
      const decodedToken: any = jwt_decode(accessToken);
      const userRoles = decodedToken.user_role_id;
      localStorage.setItem("userRole", userRoles);
      if (userRoles == "1") {
        if (this.router.url === "/") {
          this.router.navigate(["/configuration"]);
        }
      }
      if (userRoles == "2") {
        if (this.router.url === "/") {
          this.router.navigate(["/configuration"]);
        }
      }
      if (userRoles == "3") {
        if (this.router.url === "/") {
          this.router.navigate(["/my-tasks"]);
        }
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  async login(username: string, password: string): Promise<string | null> {
    try {
      const response = await this.http
        .post<any>(
          `${this.apiAuthInhouseUrl}/auth/login/`,
          {
            username,
            password,
          },
          { headers: this.header }
        )
        .toPromise();

      if (response && response.access_token) {
        const token = response.access_token;
        const refresh_t = response.refresh_token;
        this.setTokens(token, refresh_t);

        this.isAuthenticated = true;
        return null; // No error, return null.
      } else {
        this.isAuthenticated = false;
        return "Invalid credentials"; // Return an error message.
      }
    } catch (error: any) {
      console.error("Login error:", error.error.error);
      this.isAuthenticated = false;
      const errorMessage = error.error.error;
      return errorMessage;
    }
  }

  private refreshAccessToken(): Observable<{
    accessToken: string;
    refreshToken: string;
  }> {
    const loginUid = Number(localStorage.getItem("loginUid"));
    return this.http
      .post<any>(
        `${this.apiAuthInhouseUrl}/access-token-refresh/`,
        {
          refresh_token: localStorage.getItem(REFRESH_TOKEN),
        },
        { headers: this.header }
      )
      .pipe(
        map((response) => ({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        }))
      );
  }

  private startRefreshTokenJob(): void {
    timer(0, this.refreshTokenIntervalMs)
      .pipe(switchMap(() => this.refreshAccessToken()))
      .subscribe(
        (tokens) => {
          this.cookieService.set(TOKEN_KEY, tokens.accessToken);
          this.cookieService.set(REFRESH_TOKEN, tokens.refreshToken);
          localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
        },
        (error) => {
          console.error("Error refreshing tokens:", error);
        }
      );
  }

  reset(password: string, confirmPassword: string): boolean {
    if (password === confirmPassword) {
      const token = "abcdefgh12345";

      localStorage.setItem(TOKEN_KEY, token);
      this.isAuthenticated = true;
      return true;
    } else {
      this.isAuthenticated = false;
      return false;
    }
  }

  sessionTimeout(): void {
    this.resetSession();
    this.timeoutHandle = window.setTimeout(() => {
      this.logout();
    }, this.timeoutDuration);
  }

  resetSession(): void {
    window.clearTimeout(this.timeoutHandle);
  }

  getNotifications() {
    return this.http.get<any[]>( this.apiNotificationUrl + `/notification-templates/list`
    );
  }

  // Method to perform user logout
  logout(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN);
    this.isAuthenticated = false;
    this.router.navigateByUrl("/login");
  }
}