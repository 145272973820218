import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, HostListener} from '@angular/core';
import * as echarts from "echarts/core";
import { HeatmapChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, VisualMapComponent, GridComponent, GraphicComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { EffectScatterChart } from 'echarts/charts';
import { BarChart } from 'echarts/charts';
import dayjs from 'dayjs/esm';
import { StoreInsightService } from 'src/app/services/store-insight.service';
import { HeatmapPopupComponent } from 'src/app/components/pages/store-insight/heatmap-popup/heatmap-popup.component';
declare var bootstrap: any;  // Ensures Bootstrap JS is recognized
// Register the required components
echarts.use([TitleComponent, TooltipComponent, HeatmapChart, VisualMapComponent, GridComponent, GraphicComponent, CanvasRenderer, EffectScatterChart, BarChart]);
interface ROI {
  x_min: number;
  y_min: number;
  x_max: number;
  y_max: number;
}

@Component({
  selector: 'app-heatmap-geo-location',
  templateUrl: './heatmap-geo-location.component.html',
  styleUrls: ['./heatmap-geo-location.component.scss']
})
export class HeatmapGeoLocationComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('peopleCountHeatMapChart') peopleCountHeatMapChart!: ElementRef;
  @ViewChild('peopleCountHeatMapCanvas', { static: true }) peopleCountHeatMapCanvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;

  @Input() activeHeatmapResponse: any;

  selectedROIs: ROI[] = [];
  defaultROI = true;
  selectedROI = false;

  drawnRoiPaths: Path2D[] = [];
  noDataFound: boolean = false;
  selectedDateRange: { startDate: any; endDate: any };

  defaultHeatmapImage: any;
  canvasWidth: any;
  canvasHeight: any;
  imageWidth: number;
  imageHeight: number;

  peopleCountValue: number;

  gridColumns: number = 12;
  gridRows: number = 8;

  drawROIContent: boolean = false;
  activeROIData: any;
  selectedDate: any;
  selectedStore: any;
  activeHeatmapData: any;

  // For ROI drawing state
  private isDrawing: boolean = false;
  private x_min: number = 0;
  private y_min: number = 0;
  private x_max: number = 0;
  private y_max: number = 0;
  private scaleX: number = 1;
  private scaleY: number = 1;
  
  constructor(private storeInsightService: StoreInsightService) {}

  ngOnInit(): void {
    this.clearCanvas();
    this.clearROIs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeHeatmapResponse']) {
      this.selectedDate = this.activeHeatmapResponse?.selectedDate;
      this.selectedStore = this.activeHeatmapResponse?.selectedStore;
      this.defaultHeatmapImage = this.activeHeatmapResponse?.storeLayout;
      this.peopleCountValue = this.activeHeatmapResponse?.peopleCountValue;
      this.imageWidth = this.activeHeatmapResponse?.imageDimensions?.width;
      this.imageHeight = this.activeHeatmapResponse?.imageDimensions?.height;
      this.canvasWidth = this.activeHeatmapResponse?.imageDimensions?.width;
      this.canvasHeight = this.activeHeatmapResponse?.imageDimensions?.height;
    }
    if (this.activeHeatmapResponse !== undefined && this.activeHeatmapResponse !== null) {
      this.getPathwayCountHeatmapInfo();
      this.initPathwayCountHeatmapChart();
    }
    
  }

  ngAfterViewInit(): void {
    this.initializeCanvas();
    this.registerCanvasEvents();
    this.initPathwayHeatmapChart();   
    this.initPathwayCountHeatmapChart();
  }

  toggleHeatmapVisibility() {
    this.drawROIContent = !this.drawROIContent;
    this.clearCanvas();
    this.clearROIs();
  }
  

  private initializeCanvas(): void {
    this.ctx = this.peopleCountHeatMapCanvas.nativeElement.getContext("2d")!;
    this.peopleCountHeatMapCanvas.nativeElement.width = this.canvasWidth;
    this.peopleCountHeatMapCanvas.nativeElement.height = this.canvasHeight;
    this.ctx.strokeStyle = "#a81d9d";
    this.ctx.lineWidth = 2;
  }

  private registerCanvasEvents(): void {
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mousedown", this.onMouseDown.bind(this));
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mousemove", this.onMouseMove.bind(this));
    this.peopleCountHeatMapCanvas.nativeElement.addEventListener("mouseup", this.onMouseUp.bind(this));
  }

  onMouseDown(event: MouseEvent): void {
    this.isDrawing = true;
    this.x_min = event.offsetX;
    this.y_min = event.offsetY;
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDrawing) return;
    this.x_max = event.offsetX;
    this.y_max = event.offsetY;
    this.drawROI();
  }

  onMouseUp(): void {
    this.isDrawing = false;
    if (this.imageWidth !== undefined && this.imageHeight !== undefined) {
      const startX = Math.max(
        0,
        Math.min(Math.round(this.x_min / this.scaleX), this.imageWidth)
      );
      const startY = Math.max(
        0,
        Math.min(Math.round(this.y_min / this.scaleY), this.imageHeight)
      );
      const endX = Math.min(
        this.imageWidth,
        Math.max(Math.round(this.x_max / this.scaleX), 0)
      );
      const endY = Math.min(
        this.imageHeight,
        Math.max(Math.round(this.y_max / this.scaleY), 0)
      );
  
      // Ensure coordinates are normalized (start < end)
      const roi: ROI = {
        x_min: Math.min(startX, endX),
        y_min: Math.min(startY, endY),
        x_max: Math.max(startX, endX),
        y_max: Math.max(startY, endY),
      };
  
      // Save the ROI and redraw
      this.selectedROIs.push(roi);
      this.drawROI();
      this.getSelectedROIData(this.selectedROIs);
    }
  } 

  getSelectedROIData(selectedROIs: any) {
    this.storeInsightService.getSelectedROIInfo(this.selectedStore, this.selectedDate, selectedROIs).subscribe(
      (response) => {
        this.activeHeatmapData = response.results;
        this.toggleHeatmapVisibility();
        this.initPathwayCountHeatmapChart();
    },
    (error) => {
        console.error('resp:', error);
    });
  }

  private drawROI(): void {
    if (!this.ctx) return;
  
    // Clear the canvas before redrawing
    this.clearCanvas();
  
    // Draw all previously selected ROIs
    for (const roi of this.selectedROIs) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#bd1793"; // Color for existing ROIs
      this.ctx.rect(
        roi.x_min * this.scaleX,
        roi.y_min * this.scaleY,
        (roi.x_max - roi.x_min) * this.scaleX,
        (roi.y_max - roi.y_min) * this.scaleY
      );
      this.ctx.stroke();
    }
  
    // Draw the ROI being currently drawn
    if (this.isDrawing) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#ff0000"; // Color for current ROI
      this.ctx.rect(
        this.x_min,
        this.y_min,
        this.x_max - this.x_min,
        this.y_max - this.y_min
      );
      this.ctx.stroke();
    }
  }

  private clearCanvas(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }
  }

  clearROIs(): void {
    this.selectedROIs = [];
  }

  getPathwayCountHeatmapInfo(): void {
    const cellWidth = this.imageWidth / this.gridColumns;
    const cellHeight = this.imageHeight / this.gridRows;
    const basicCoordinates: { x_min: number, x_max: number, y_min: number, y_max: number }[] = [];
    for (let row = 0; row < this.gridRows; row++) {
      for (let col = 0; col < this.gridColumns; col++) {
          const x_min = col * cellWidth;
          const y_min = row * cellHeight;
          const x_max = x_min + cellWidth;
          const y_max = y_min + cellHeight;
          basicCoordinates.push({ x_min, x_max, y_min, y_max });
      }
    }
    this.selectedROIs = basicCoordinates;
    this.storeInsightService.getPredefinedROIInfo(this.selectedStore, this.selectedDate, basicCoordinates).subscribe((resp) => {
      console.log('resp', resp);
      this.activeHeatmapData = resp?.data;
      this.peopleCountValue = resp?.peopleCount;
      this.initPathwayCountHeatmapChart();
      
    });
  }

  // getPeopleCountValue() {
  //   this.storeInsightService.showLoader();
  //   this.storeInsightService.getPredefinedROIInfo(this.selectedStore, this.selectedDate, this.selectedROIs).subscribe({
  //     next: (resp: any) => {
  //     if(resp){
  //       this.peopleCountValue = resp?.peopleCount;
  //     }
  //   },
  //   error: (err) => {
  //     console.error("Error fetching data:", err);
  //   },
  //   complete: () => {
  //     this.storeInsightService.hideLoader(); // Hide loader after API response
  //   }
  // });
  // }

  initPathwayCountHeatmapChart(): void {
    const chartDom = this.peopleCountHeatMapChart.nativeElement;
    const myHeatmapChart = echarts.init(chartDom);

    const activeChartData = this.activeHeatmapData || [];

    if (!Array.isArray(activeChartData)) {
      console.error('Error: activeHeatmapData is not an array', this.activeHeatmapData);
      return; // Stop execution if data is invalid
    }

    const responseData = {
      data: activeChartData.map((item: { coordinates: any[]; unique_count: any; }) => ({
        coordinates: item.coordinates.map(coord => parseFloat(coord.toFixed(2))),
        unique_count: item.unique_count
      }))
    };

    const cellWidth = this.imageWidth / this.gridColumns;
    const cellHeight = this.imageHeight / this.gridRows;

    const heatmapData = responseData.data
    .filter((item: any) => item.unique_count > 0)
    .map((item: any) => {
      const [xStart, xEnd, yStart, yEnd] = item.coordinates;

      // Calculate the center of the coordinates
      const x = (xStart + xEnd) / 2;
      const y = (yStart + yEnd) / 2;

      // Scale the coordinates to fit the canvas (1200x500)
      const scaledX = (x / this.imageWidth) * this.imageWidth;
      const scaledY = (y / this.imageHeight) * this.imageHeight;

      // Use unique_count as the value
      const value = item.unique_count;
      return [scaledX, scaledY, value];
    });
    
    // Generate grid overlay with borders for testing
    const gridLines = [];
    for (let row = 0; row < this.gridRows; row++) {
      for (let col = 0; col < this.gridColumns; col++) {
        const x = col * cellWidth;
        const y = row * cellHeight;
        gridLines.push({
          type: 'rect',
          left: x,
          top: y,
          shape: {
            width: cellWidth,
            height: cellHeight,
          },
          style: {
            stroke: '#ddd',
            fill: 'transparent',
            lineWidth: 1,
          },
          z: -5,
        });
      }
    }
  
    // Configure the heatmap chart options
    const option = {
      tooltip: {},
      grid: {
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '0%',
        containLabel: false,
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: this.imageWidth,
        show: false,
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: this.imageHeight,
        show: false,
      },
      visualMap: {
        min: 0,
        max: 100,
        // left: "20%",
        calculable: true,
        inRange: {
          color: ['#e2614a', '#8e24aa'],
        },
        show: false,
      },
      series: [
        {
          name: 'People Heatmap',
          type: 'effectScatter',
          coordinateSystem: 'cartesian2d',
          data: heatmapData,
          symbolSize: 30,
          showEffectOn: 'render',
          rippleEffect: {
            scale: 2.5,
            brushType: 'stroke',
          },
          encode: {
            tooltip: 2,
          },
          z: 100,
        },
      ],
      graphic: [
        {
          type: 'image',
          left: 'center',
          top: 'center',
          z: -10,
          style: {
            image: this.defaultHeatmapImage,
            width: this.imageWidth,
            height: this.imageHeight,
            opacity: 1,
          },
        },
         ...gridLines, // Add the grid lines to the graphic layer
      ],
    };
  
    // Set the chart options
    myHeatmapChart.setOption(option);
  }
  

  initPathwayHeatmapChart(): void {
    const chartDom = document.getElementById('peopleCountBarChart')!;
    const peopleCountBarChartData = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: { 
          type: 'shadow' 
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '10%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: ['6AM to 9AM', '9AM to 12PM', '12PM to 6PM', '6PM to 9PM'],
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
      },
      series: [
        {
          realtimeSort: true,
          name: 'Values',
          type: 'bar',
          data: [454, 404, 459, 300],
          label: {
            show: true,
            position: 'right',
            valueAnimation: true
          },
          barCategoryGap: '20%',
        },
      ],
    };
    peopleCountBarChartData.setOption(option);
  }

  onDateRangeSelected(event: any): void {
    if (event.startDate && event.endDate) {
      const startDate = dayjs(event.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(event.endDate).format('YYYY-MM-DD');
      this.noDataFound = false;
      this.selectedDateRange = {
        startDate: startDate,
        endDate: endDate
      };
    } else {
      this.noDataFound = true;
    }
  }

}
