import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TimezoneService } from '../../../services/timezone.service';

@Pipe({
  name: 'timezone',
  pure: false  // Ensures the pipe is updated when timezone changes
})
export class TimezonePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: string | Date, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    if (!value) return '';

    const selectedTimezone = this.timezoneService.getTimezone();  // Get the selected timezone from service

    const dateTime = DateTime.fromISO(value.toString()).setZone(
      selectedTimezone === 'CST' ? 'America/Chicago' : 'UTC'
    );

    return dateTime.toFormat(format);  // Format the datetime according to the selected timezone
  }
}