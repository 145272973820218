import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Subject, BehaviorSubject } from "rxjs";
import { from, Observable, timer, lastValueFrom } from "rxjs";
import { switchMap, map } from "rxjs/operators";

// import { environment } from "src/environments/environment.dev";
import { environment } from "src/environments/environment.qa";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";

const TOKEN_KEY = "auth_token";
const REFRESH_TOKEN = "refresh_token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticated: boolean = false;
  private timeoutHandle: number;
  private readonly timeoutDuration = 1 * 60 * 1000; // 1 minute
  private readonly refreshTokenIntervalMs = 10 * 60 * 1000; // 5 minutes

  apiAuthUrl = environment.apiAuthUrl;
  apiAuthInhouseUrl = environment.apiAuthInHouseUrl;
  apiNotificationUrl = environment.apiNotificationUrl;
  private apiKey: string = "";
  private clientId: string = environment.authConfig.clientId;
  private clientSecret: string = environment.authConfig.clientSecret;

  user_role_id: string = "3";

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private injector: Injector
  ) {
    this.isAuthenticated = !!localStorage.getItem(TOKEN_KEY);
    setTimeout(() => {
      this.http = this.injector.get(HttpClient);
    });
    this.initializeApiKey();
  }

  // Initialize API key at startup
  private initializeApiKey(): void {
    const apiKeyExpiration = localStorage.getItem("apiKeyExpiration");
    if (!apiKeyExpiration || parseInt(apiKeyExpiration) < Date.now()) {
      this.fetchNewApiKey().subscribe();
    } else {
      this.apiKey = localStorage.getItem("currentApiKey") || '';
    }
  }

  // Fetch a new API key from the server
  private fetchNewApiKey(): Observable<string> {
    return this.http.post<any>(`${this.apiAuthInhouseUrl}auth/generate-api-key/`, {
      client_id: this.clientId,
      client_secret: this.clientSecret,
    }).pipe(
      map((response) => {
        const { api_key, expires_at } = response;
        this.apiKey = api_key; // Update instance variable
        const expirationTime = new Date(expires_at).getTime() - Date.now();

        // Store in localStorage for session persistence
        localStorage.setItem("currentApiKey", api_key);
        localStorage.setItem("apiKeyExpiration", new Date(expires_at).getTime().toString());

        // Schedule next key refresh
        setTimeout(() => this.fetchNewApiKey().subscribe(), expirationTime);
        return api_key;
      })
    );
  }

  // Getter to access apiKey
  public getApiKey(): string {
    return this.apiKey;
  }

  // Get headers with the current API key
  private getHeaders(): Observable<HttpHeaders> {
    return this.apiKey
      ? from(Promise.resolve(this.buildHeaders()))
      : this.fetchNewApiKey().pipe(map(() => this.buildHeaders()));
  }

// Build headers with API key
  private buildHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "X-API-Key": this.apiKey,
    });
  }

  // Method to check if the user is authenticated
  isAuthenticate(): boolean {
    return this.isAuthenticated;
  }
  
  // Example: Login method using dynamic headers
  async login(username: string, password: string): Promise<string | null> {
    try {
      const headers = await lastValueFrom(this.getHeaders());
      const response = await lastValueFrom(
        this.http.post<any>(
          `${this.apiAuthInhouseUrl}/auth/login/`,
          { username, password },
          { headers }
        )
      );
  
      if (response?.access_token) {
        localStorage.setItem('accessToken', response?.access_token);
        this.setTokens(response.access_token, response.refresh_token);
        this.isAuthenticated = true;
        return null; // Success
      } else {
        return "Invalid credentials"; // Handle unexpected response
      }
    } catch (error: any) {
      console.error("Login error:", error?.error?.error || error.message);
      return error?.error?.error || "Login failed";
    }
  }
  
  getAccessToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  setTokens(accessToken: string, refreshToken: string): void {
    this.cookieService.set(TOKEN_KEY, accessToken);
    this.cookieService.set(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    this.startRefreshTokenJob();
  }

  checkAndStartRefreshTokenJob(): void {
    const storedRefreshToken = localStorage.getItem("refresh_token");
    if (storedRefreshToken) {
      this.startRefreshTokenJob();
    }
  }

  navigateUser() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    if (!accessToken) {
      console.error("No access token found in localStorage.");
      return;
    }

    try {
      const decodedToken: any = jwt_decode(accessToken);
      const userRoles = decodedToken.role_name;
      localStorage.setItem("userRole", userRoles);

      if (userRoles === "csm") {
        this.router.navigate(["/configuration"]);
      } else if (userRoles === "store-user") {
        this.router.navigate(["/my-tasks"]);
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  private refreshAccessToken(): Observable<{
    accessToken: string;
    refreshToken: string;
  }> {
    return this.getHeaders().pipe(
      switchMap((headers) => 
        this.http.post<any>(
          `${this.apiAuthInhouseUrl}auth//access-token-refresh/`,
          {
            refresh_token: localStorage.getItem(REFRESH_TOKEN),
          },
          { headers }
        )
      ),
      map((response) => ({
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
      }))
    );
  }
  

  private startRefreshTokenJob(): void {
    timer(0, this.refreshTokenIntervalMs)
      .pipe(switchMap(() => this.refreshAccessToken()))
      .subscribe(
        (tokens) => {
          this.cookieService.set(TOKEN_KEY, tokens.accessToken);
          this.cookieService.set(REFRESH_TOKEN, tokens.refreshToken);
          localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
        },
        (error) => {
          console.error("Error refreshing tokens:", error);
        }
      );
  }

  reset(password: string, confirmPassword: string): boolean {
    if (password === confirmPassword) {
      const token = "abcdefgh12345";

      localStorage.setItem(TOKEN_KEY, token);
      this.isAuthenticated = true;
      return true;
    } else {
      this.isAuthenticated = false;
      return false;
    }
  }

  sessionTimeout(): void {
    this.resetSession();
    this.timeoutHandle = window.setTimeout(() => {
      this.logout();
    }, this.timeoutDuration);
  }

  resetSession(): void {
    window.clearTimeout(this.timeoutHandle);
  }

  getNotifications() {
    return this.http.get<any[]>( this.apiNotificationUrl + `/notification-templates/list`
    );
  }

  // Method to perform user logout
  logout(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem('selectedTimezone');
    this.isAuthenticated = false;
    this.router.navigateByUrl("/login");
  }
}