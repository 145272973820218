<div class="heat-map-container">
    <div class="heat-map-body">
      <div class="heat-map-content">
        <div class="content-left d-flex align-items-center justify-content-start">
          <div class="point-filters">
            <label class="heading">Start point: </label>
            <div class="dropdown">
              <select class="camera-values" (change)="changeFilterFrom($event)" [disabled]="!dashboardWidget"
                [ngModel]="filterFrom">
                <option value="">All</option>
                <option *ngFor="let filter of filtersData" [value]="filter.name">{{filter.name}}</option>
              </select>
            </div>
          </div>
          <div class="point-filters">
            <label class="heading">End point: </label>
            <div class="dropdown">
              <select class="camera-values" (change)="changeFilterTo($event)" [disabled]="!dashboardWidget"
                [ngModel]="filterTo">
                <option value="">All</option>
                <option *ngFor="let filter of filtersData" [value]="filter.name"
                  [disabled]="filter.name === filterFrom && filter.name !== 'All'">
                  {{filter.name}}</option>
              </select>
            </div>
          </div>

          <div class="daterange-filter justify-content-end">
            <label class="heading">Select Dates: </label>
              <div class="daterange-area">
              <!-- <input
                type="text"
                ngxDaterangepickerMd
                formControlName="selectedDateRange"
                class="form-control daterange-input"
                placeholder="Choose date"
                (change)="onDateRangeSelected($event)" 
                [showDropdowns]="true"
                [showCancel]="true"/>
                <span class="calendar-icon"></span> -->
              </div>
          </div>
        </div>
      </div>
      <div class="container-image" [class.no-data]="noDataFound">
        <div #pathwayChart class="pathway-container"></div>
        <div class="no-data-found">
          <div class="d-flex justify-content-center align-items-center">
            <p>No matching path</p>
          </div>
        </div>
      </div>
    </div>
  </div>