import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private timezoneSubject = new BehaviorSubject<string>(localStorage.getItem('selectedTimezone') || 'UTC');
  currentTimezone$ = this.timezoneSubject.asObservable(); // Observable for components to subscribe

  constructor(private http: HttpClient) {}

  setTimezone(timezone: string) {
    localStorage.setItem('selectedTimezone', timezone);  // Save to localStorage
    this.timezoneSubject.next(timezone);  // Emit the selected timezone
  }

  getTimezone(): string {
    return this.timezoneSubject.getValue();
  }

  getUseCases(): Observable<any> {
    const url = `../../assets/mock-json/usecase.json`;
    return this.http.get<any[]>(url);
  }
}
