import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";


@Component({
  selector: "app-add-roles",
  templateUrl: "./add-roles.component.html",
  styleUrls: ["./add-roles.component.scss"],
})
export class AddRolesComponent implements OnInit {
  @Input() filteredOrgs: any[];
  @Input() rolesData: any;
  @ViewChild("autoOrgDrop") autoOrgDrop: any;

  isEditing: boolean = false;
  isStoreExist: boolean = false;
  checkStore: any;
  errorMessage: boolean = false;
  isCSAdmin: boolean = false;
  providedErrorMessage: string = "";
  pageSize: number = this.configurationService.pageSize;
  currentPage: number = 1;
  addRoleForm: FormGroup;
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any = "";
  selectedTenantName: string;
  isSubmitted: boolean = false;
  isVisible: boolean = false;
  isAllActionsSelected = false;
  isAllResourcesSelected = false;
  processing: boolean = false;  
  data: any;
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';

  keyword = "name";
  placeholderOrg = "Select the Organization";
  placeholderRole = "Select the Role";

  loggedUser: any;
  orgData: any;
  orgname: any;
  isChecked: boolean = true;
  isAlertChecked: boolean = false;


  anyCheckboxSelected: boolean;

  selectedKey: string = '';
  selectedValue: string = '';
  selectedValues: string[] = [];


  resourceList: any[] = [];
  activeResource: any = {};
  actionList: any = {};
  //actionList:  any[] = [];
  rollsList: any[];
  selectedRollName: string;
  roleId: any;
  inherited_role_id: any;
  inherit_role_id: any = null;
  policyId: any;
  role_policy_id: any
  roleObj: {
    org_id: string;
    role_id: string;
    role_name: string;
  };

  policyObj: {
    org_id: string;
    policy_id?: string;
    policy_name: string;
    policy_json: any
  };
  rolePolicyObj: {
    org_id: string;
    role_policy_id: string;
    role_id: string;
    policy_id: string;
  }
  inheritedRoleObj: {
    org_id: string;
    inherit_id: string;
    role_id: string;
    inherited_role_id: string;
  }
  getRoleObj: {
    org_id: string;
    role_id: string;
  };
  fetchInheritRoleObj: {
    org_id: string;
    inherit_id: string;
  }
  policy_json: any = {};
  selectedActionsLists: any = [];
  selectedActionsObj: any = {};


  // roleObj = {org_id :"",role_id :"",role_name:""};
  selectedActionsList: any = {};
  finalList: any = {};
  isRoleChange: boolean = false;
  btnName: string = "Create Role";
  noResourceSelected: boolean = false;
  submitBtnDisabled: boolean = false;
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private alertMessagesService: AlertMessagesService,
  ) {
    this.addRoleForm = this.fb.group({
      tenantId: ["", [Validators.required]],
      roleName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z]+[-_]?[a-zA-Z]*([-_](?![-_]))?[a-zA-Z]+$/)]],
      role_id: ["", null],
    });
    this.commonService.selectedOrganization$.subscribe(org => {
      if (org) {
        this.selectedTenantId = org.tenantId;
        this.selectedTenantName = org.tenantName;
      }
    });
    this.loggedUser = localStorage.getItem("userRole");
    this.roleObj = {
      org_id: "",
      role_id: "",
      role_name: ""
    };
    this.policyObj = {
      org_id: "",
      policy_id: "",
      policy_name: "",
      policy_json: {}
    }
    this.rolePolicyObj = {
      org_id: "",
      role_id: "",
      policy_id: "",
      role_policy_id: ""
    }
    this.inheritedRoleObj = {
      org_id: "",
      inherit_id: "",
      role_id: "",
      inherited_role_id: ""
    }
    this.getRoleObj = {
      org_id: "",
      role_id: "",
    }
    this.fetchInheritRoleObj = {
      org_id: "",
      inherit_id: ""
    }
  }
  get fc() {
    return this.addRoleForm.controls;
  }

  async ngOnInit() {
    this.submitBtnDisabled = true;
    await this.getAllResourseData();
    this.assignDefaultValues();
    this.configByRole();
    this.getAllRoles();
    if (this.rolesData?.role_id) {
      this.btnName = "Update Role";
      this.roleObj.role_id = this.rolesData?.role_id;
      this.roleId = this.rolesData?.role_id;
      this.getRolesById();
    } else {
      this.submitBtnDisabled = false;
    }
  }
  getRolesById() {
    this.getRoleObj.org_id = this.selectedTenantId;
    this.getRoleObj.role_id = this.rolesData.role_id;
    this.configurationService.fetchRole(this.getRoleObj.role_id).subscribe((resp: any) => {
      if (resp.message == 'Success') {
        this.selectedRollName = resp.data.inherited_role_name;
        this.inherit_role_id = resp.data.inherited_role_id
        this.inherited_role_id = resp.data.inherit_table_id;
        this.policy_json = resp.data.policy_json;
        this.policyId = resp.data.policy_id;
        this.resourceList.forEach((resource: any) => {
          if (this.policy_json.hasOwnProperty(resource?.resource_name)) {
            if (this.policy_json[resource?.resource_name][0] == "*") {
              resource.isAllActionsSelected = true;
              resource.isActionsPartiallySelected = false;
              this.actionList = resource;
              this.actionList.actionsjson.actionsList = resource.actionsjson.actions.map((action: any) => ({
                action_name: action,
                selected: true
              }));
            } else {
              resource.isAllActionsSelected = false;
              this.actionList = resource;
              resource.isActionsPartiallySelected = true;
              this.actionList.actionsjson.actionsList = resource.actionsjson.actions.map((action: any) => ({
                action_name: action,
                selected: (this.policy_json[resource?.resource_name].includes(action)) ? true : false
              }));
            }
            this.resourceList.map((item:any) => item.active = false);
            resource.active = true;
          }
        });
        if (this.inherited_role_id) {
          this.fetchInheritedRole();
        }
        this.submitBtnDisabled = false;
      } else {
        this.submitBtnDisabled = false;
      }
    }, (err: any) => {
      this.submitBtnDisabled = false;
    });
  }
  fetchInheritedRole() {
    this.fetchInheritRoleObj.org_id = this.selectedTenantId;
    this.fetchInheritRoleObj.inherit_id = this.inherited_role_id;
    this.configurationService.fetchInheritedRole(this.fetchInheritRoleObj.inherit_id).subscribe((resp: any) => {
      if (resp.message == 'success') {
      }
    });
  }
  createPolicy() {
    this.resourceList.forEach(resitem => {
      if (resitem?.isAllActionsSelected) {
        this.finalList[resitem?.resource_name] = ['*'];
      } else if (resitem?.isActionsPartiallySelected) {
        resitem.actionsjson.actionsList.forEach((action: any) => {
          if (action.selected) {
            if (!this.finalList[resitem?.resource_name]) {
              this.finalList[resitem?.resource_name] = [];
            }
            // Push the action_name to the array
            this.finalList[resitem?.resource_name].push(action.action_name);
          }
        });
      }
    });
    this.policyObj.org_id = this.selectedTenantId;
    this.policyObj.policy_id = this.isEditing ? this.policyId : "";
    this.policyObj.policy_name = this.roleObj.role_name + '-policy';
    this.policyObj.policy_json = this.finalList;
  }
  createRoleAndPolicy() {
    this.rolePolicyObj.org_id = this.selectedTenantId;
    this.rolePolicyObj.policy_id = this.policyId;
    this.rolePolicyObj.role_id = this.roleId;
    this.rolePolicyObj.role_policy_id = "";
    this.configurationService.createOrEditRollspolicy(this.rolePolicyObj).subscribe((resp: any) => {
      if (resp.message == 'success') {
        this.role_policy_id = resp.role_policy_id;
        if (this.isRoleChange) {
          this.createOrEditInheritedRole();
        }
      }
    });
  }
  createOrEditInheritedRole() {
    this.inheritedRoleObj.org_id = this.selectedTenantId;
    this.inheritedRoleObj.inherit_id = "";
    this.inheritedRoleObj.role_id = this.roleId;
    this.inheritedRoleObj.inherited_role_id = this.inherit_role_id;

    this.configurationService.createOrEditInheritedRole(this.inheritedRoleObj).subscribe((resp: any) => {
      if (resp.message == 'success') {
        this.isRoleChange = false;
      }
    });
  }

  configByRole() {
    if (this.loggedUser == "csm") {
      this.isCSAdmin = true;
      this.filterOrgData();
    }
    if (this.loggedUser == "org-admin") {
      this.isCSAdmin = false;
      //   this.selectedTenantId = this.defaultTenantId;
    }
  }

  assignDefaultValues() {
    if (this.rolesData) {
      this.isEditing = !this.isEditing;
      this.addRoleForm.patchValue({
        roleName: this.rolesData?.role_name,
        role_id: this.rolesData?.role_id,
      });
      this.selectedTenantId = this.rolesData?.tenantId;
      this.selectedTenantName = this.rolesData?.tenantName;
    }
  }
  getAllResourseData() {
    return new Promise((resolve) => {
      this.configurationService.getAllResourseData(this.selectedTenantId).subscribe((resp: any) => {
        this.resourceList = resp.data;
        this.resourceList.forEach(res => {
          res.isAllActionsSelected = false;
          res.isActionsPartiallySelected = false;
          res.actionsjson.actionsList = [];
          let i = 0
          res.actionsjson.actions.forEach((action: any, index: any) => {
            res.actionsjson.actionsList.push({ action_name: action, action_id: index, selected: false })
          });
          i++;
        });
        resolve(true);
      }, (err: any) => {
        resolve(true);
      });
    });
  }
  getAllRoles() {
    if( !this.selectedTenantId ) {
      return;
    }
    this.configurationService.getAllRolesDataByOrgId(this.selectedTenantId).subscribe((resp: any) => {
      this.rollsList = resp.data;
      this.rollsList.map(
        (rolls: any) => (rolls.name = `${rolls.role_name}`, rolls.id = `${rolls.role_id}` )
      );
    });
  }


  filterOrgData() {
    this.filteredOrgs?.map((org: any) => (org.name = `${org.tenantName}`));
    this.filteredOrgs = this.filteredOrgs?.filter(
      (orgData: any) => orgData.status === true
    );
  }

  toggleOrgPanel(e: any): void {
    e.stopPropagation();
    this.autoOrgDrop.open();
  }

  clearOrganization() {
    // this.selectedTenantId = "";   
    this.resourceList = [];
    this.actionList = {};
  }
  clearInheritedRole() {
    this.selectedRollName = "";
    this.inherit_role_id = null;
  }

  changeOrganization(event: any) {
    this.selectedTenantId = event.tenantId;
    this.getAllResourseData();
    this.getAllRoles();
  }
  changeRole(event: any) {
    this.inherit_role_id = event.role_id;
    this.isRoleChange = true;
}

  storeNameCheckUnique() {
    this.configurationService
      .getStoreNameUnigue(this.checkStore, this.selectedTenantId)
      .subscribe((resp: any) => {
        if (resp.status === false) {
          this.isStoreExist = false;
        } else {
          this.isStoreExist = true;
        }
      });
  }

  createSelectedActionResourceList() {
    this.selectedActionsObj = {};
    this.resourceList.forEach(resitem => {
      if (resitem?.isAllActionsSelected) {
        this.selectedActionsObj[resitem?.resource_name] = resitem?.actionsjson?.actions ?? [];
      } else if (resitem?.isActionsPartiallySelected) {
        resitem.actionsjson.actionsList.forEach((action: any) => {
          if (action.selected) {
            if (!this.selectedActionsObj[resitem?.resource_name]) {
              this.selectedActionsObj[resitem?.resource_name] = [];
            }
            // Push the action_name to the array
            this.selectedActionsObj[resitem?.resource_name].push(action.action_name);
          }
        });
      }
    });

    this.selectedActionsLists = [];
    Object?.entries(this.selectedActionsObj)?.forEach(([key, value]) => {
      this.selectedActionsLists.push({
        resource: key,
        action: value,
        active: ""
      });
    });
    if( this.selectedActionsLists?.length > 0 ) {
      this.selectedActionsLists[0].active = "active";
    }
  }

  submitForm() {
    this.isSubmitted = true;
    this.addRoleForm.patchValue({
      tenantId: this.selectedTenantId,
    });
    if (this.rolesData) {
      this.addRoleForm.patchValue({
        tenantId: this.selectedTenantId,
      });
    }
    Object.keys(this.addRoleForm.controls).forEach((controlName) => {
      const control = this.addRoleForm.get(controlName);
      // Check for validation errors
      if (control?.invalid) {
        this.processing = false;
      }
    });

    this.roleObj.org_id = this.selectedTenantId;
    this.roleObj.role_name = this.addRoleForm.controls['roleName'].value;
    this.noResourceSelected = this.resourceList.every(item => !item.isActionsPartiallySelected && !item.isAllActionsSelected);
    if (this.rolesData?.role_id && this.addRoleForm.valid && !this.noResourceSelected) {
      this.createSelectedActionResourceList();
      const resourceHtml = `<ul class="resource-list-container" id="resourceContainer">${this.selectedActionsLists.map((item: any) => `<li class="resource-item ${item.active}" id="resourceId" resource-id="${item.resource}">${item.resource}</li>`).join('')}</ul>`;
      const actionsHtml = `<ul class="action-list-container" id="actionContainer">${this.selectedActionsLists?.[0]?.action?.map((item: any) => `<li class="action-item" id="actionId" action-id="${item}">${item}</li>`).join('')}</ul>`;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: 'alert-warning-container role-org-container',
          htmlContainer: 'alert-warning-content role-org-content',
          confirmButton: "btn btn-submit",
          cancelButton: "btn btn-cancel",
          icon: "alert-warning-icon",
          title: "alert-warning-title role-org-title",
        },
        buttonsStyling: false,
        didOpen: () => {
          /// code to change the html content for Actions after user clicks on Resource list before submitting to create role 
          const htmlContent: any = swalWithBootstrapButtons.getHtmlContainer();
          htmlContent.querySelector('#resourceContainer').addEventListener('click', (event: any) => {
            if (event.target && event.target.classList.contains('resource-item')) {
              const resourceId = event.target.getAttribute('resource-id');
              let index = this.selectedActionsLists.findIndex((obj: any) => obj.resource === resourceId);
              if( index != -1 ) {
                this.selectedActionsLists.map((obj: any) => obj.active = "");
                this.selectedActionsLists[index].active = "active";
                // Remove the 'active' class from the <li> element
                htmlContent.querySelector('li.resource-item.active').classList.remove('active');
                event.target.classList.add('active')
                const actionsHtml = `<ul class="action-list-container" id="actionContainer">${this.selectedActionsLists?.[index]?.action?.map((item: any) => `<li class="action-item" id="actionId" action-id="${item}">${item}</li>`).join('')}</ul>`;
                // Get the parent element with class 'role-actions'
                let roleActions = htmlContent.querySelector('.role-actions');
                // Create a new div element with the desired HTML content
                let newActionContainer = document.createElement('div');
                newActionContainer.innerHTML = actionsHtml;
                let newActionsContainer = newActionContainer.firstChild;
                // Get the existing actionContainer element
                let actionContainer = roleActions.querySelector('#actionContainer');
                // Replace the actionContainer with the new content
                roleActions.replaceChild(newActionsContainer, actionContainer);
              }
            }
          });
        }
      });
      swalWithBootstrapButtons.fire({
        iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" />`,
        title: `<div class="role-title-container"><div class="role-title-input"><label class="role-label">Organization Name:</label><input class="role-input" type="text" value="${this.selectedTenantName}" disabled="true"></div><div class="role-title-text">Please confirm your role permissions</div></div>`,
        html: `<div class="role-title-container"><div class="role-title-input"><label class="role-label">Role Name:</label><input class="role-input" type="text" value="${this.roleObj.role_name}" disabled="true"></div><div class="role-resources"><label class="role-label">Resources:</label>${resourceHtml}</div><div class="role-actions"><label class="role-label">Actions:</label>${actionsHtml}</div></div>`,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Back",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.createPolicy();
          const data = {
            "role_name": this.roleObj.role_name,
            "inherit_role_id": this.inherit_role_id,
            "policy_json": this.policyObj.policy_json,
            "inherit_table_id" : this.inherited_role_id
          }
          this.processing = true;
          this.configurationService
          .updateRoleRelation(this.rolesData?.role_id,data)
          .subscribe({
            next: (resp: any) => {
              if (resp.message === "Success") {
                this.configurationService.updateRoleInUserManagement(this.rolesData?.role_id,this.roleObj.role_name,).subscribe({
                  next: (res:any)=>{   
                    this.processing = false;
                    this.activeModal.close();
                    this.alertMessagesService.alertWithSuccess("Role updated successfully.");
                  }, error: (error: any) => {
                    this.processing = false;
                    this.alertMessagesService.alertWithError("Failed to update role");
                  }
                })
              }
            }, error: (error: any) => {
              this.processing = false;
              this.alertMessagesService.alertWithError("Failed to update role");
            }
          });
        }
      });
    } else if (this.addRoleForm.valid && !this.noResourceSelected) {
      this.createSelectedActionResourceList();
      const resourceHtml = `<ul class="resource-list-container" id="resourceContainer">${this.selectedActionsLists.map((item: any) => `<li class="resource-item ${item.active}" id="resourceId" resource-id="${item.resource}">${item.resource}</li>`).join('')}</ul>`;
      const actionsHtml = `<ul class="action-list-container" id="actionContainer">${this.selectedActionsLists?.[0]?.action?.map((item: any) => `<li class="action-item" id="actionId" action-id="${item}">${item}</li>`).join('')}</ul>`;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: 'alert-warning-container role-org-container',
          htmlContainer: 'alert-warning-content role-org-content',
          confirmButton: "btn btn-submit",
          cancelButton: "btn btn-cancel",
          icon: "alert-warning-icon",
          title: "alert-warning-title role-org-title",
        },
        buttonsStyling: false,
        didOpen: () => {
          /// code to change the html content for Actions after user clicks on Resource list before submitting to create role 
          const htmlContent: any = swalWithBootstrapButtons.getHtmlContainer();
          htmlContent.querySelector('#resourceContainer').addEventListener('click', (event: any) => {
            if (event.target && event.target.classList.contains('resource-item')) {
              const resourceId = event.target.getAttribute('resource-id');
              let index = this.selectedActionsLists.findIndex((obj: any) => obj.resource === resourceId);
              if( index != -1 ) {
                this.selectedActionsLists.map((obj: any) => obj.active = "");
                this.selectedActionsLists[index].active = "active";
                // Remove the 'active' class from the <li> element
                htmlContent.querySelector('li.resource-item.active').classList.remove('active');
                event.target.classList.add('active')
                const actionsHtml = `<ul class="action-list-container" id="actionContainer">${this.selectedActionsLists?.[index]?.action?.map((item: any) => `<li class="action-item" id="actionId" action-id="${item}">${item}</li>`).join('')}</ul>`;
                // Get the parent element with class 'role-actions'
                let roleActions = htmlContent.querySelector('.role-actions');
                // Create a new div element with the desired HTML content
                let newActionContainer = document.createElement('div');
                newActionContainer.innerHTML = actionsHtml;
                let newActionsContainer = newActionContainer.firstChild;
                // Get the existing actionContainer element
                let actionContainer = roleActions.querySelector('#actionContainer');
                // Replace the actionContainer with the new content
                roleActions.replaceChild(newActionsContainer, actionContainer);
              }
            }
          });
        }
      });
      swalWithBootstrapButtons.fire({
        iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" />`,
        title: `<div class="role-title-container"><div class="role-title-input"><label class="role-label">Organization Name:</label><input class="role-input" type="text" value="${this.selectedTenantName}" disabled="true"></div><div class="role-title-text">Please confirm your role permissions</div></div>`,
        html: `<div class="role-title-container"><div class="role-title-input"><label class="role-label">Role Name:</label><input class="role-input" type="text" value="${this.roleObj.role_name}" disabled="true"></div><div class="role-resources"><label class="role-label">Resources:</label>${resourceHtml}</div><div class="role-actions"><label class="role-label">Actions:</label>${actionsHtml}</div></div>`,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Back",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.createPolicy();
          this.data ={
            "role_name": this.roleObj.role_name,
            "policy_json": this.policyObj.policy_json,
            "policy_name": this.policyObj.policy_name,
            "inherit_role_id": this.inherit_role_id
          }
          this.processing = true;
          this.configurationService
            .createRoleRelation(this.data)
            .subscribe({
              next: (resp: any) => {
                if (resp.message === "Success") {
                  this.roleId = resp.role_id;
                  const data = {
                    "id" : resp.role_id,
                    "name" : resp.role_name,
                    "tenant_id" : this.selectedTenantId
                  }
                  this.configurationService.createRoleInPitaya(data).subscribe({
                    next: (res:any)=>{   
                      this.processing = false;
                      this.activeModal.close();
                      this.alertMessagesService.alertWithSuccess("Role created successfully.");
                    }, error: (error: any) => {
                      this.processing = false;
                      this.alertMessagesService.alertWithError("Failed to create role");
                    }
                  })
                }
              }, error: (error: any) => {
                this.processing = false;
                this.alertMessagesService.alertWithError("Failed to create role");
              }
            });
        }
      });
    } 

  }

  selectResource(event: any, resitem: any) {
    this.actionList = {};
    this.resourceList.forEach(item => {
      if (item.resourceid == resitem.resourceid) {
        this.actionList = item;
        item.parentId = resitem.resourceid;
        item.active = true;
      } else {
        item.active = false;
      }
    });
  }

  changeResource(event: any, resitem: any) {
    var checked: any = undefined;
    if (event.target.checked == false) {
      checked = false;
      resitem.isAllActionsSelected = false;
      this.resourceList.forEach(item => {
        if (item.resourceid == resitem.resourceid) {
          this.actionList = item;
          this.actionList.actionsjson?.actionsList?.forEach((abc: any) => { abc.selected = checked });
        }
      });

      if (this.selectedActionsList.hasOwnProperty(resitem?.resource_name)) {
        delete this.selectedActionsList[resitem?.resource_name];
      }
    } else if ((event.target.checked == true || resitem.isAllActionsSelected == true)) {
      checked = true;
      if (resitem.isAllActionsSelected == false) {
        resitem.isAllActionsSelected = true;
        this.resourceList.forEach(item => {
          if (item.resourceid == resitem.resourceid) {
            this.actionList = item;
            this.actionList.actionsjson?.actionsList?.forEach((abc: any) => { abc.selected = checked });

          }
        });

      }
      if (resitem?.resource_name) {
        this.selectedActionsList[resitem?.resource_name] = ['*'];
      }
    }
    this.actionList = {};
    this.resourceList.forEach(item => {
      if (item.resourceid == resitem.resourceid) {
        this.actionList = item;
        item.selected = checked; // need to recheck the logic
        item.isAllActionsSelected = this.actionList.actionsjson?.actionsList?.every((item: any) => checked);
        item.isActionsPartiallySelected = item.isAllActionsSelected == false ? this.actionList.actionsjson?.actionsList?.filter((item: any) => checked).length > 0 : false;
        item.parentId = resitem.resourceid;
        this.actionList.actionsjson?.actionsList?.forEach((abc: any) => { abc.selected = checked });
      }
    });
    this.noResourceSelected = this.resourceList.every(item => !item.isActionsPartiallySelected && !item.isAllActionsSelected);
  }

  selectAction(event: any, resitem: any) {
    const checked = event.target.checked;
    this.actionList.actionsjson.actionsList.forEach((item: any) => {
      if (item.action_name == resitem.action_name) {
        item.selected = checked;
      }
    });

    this.actionList.isAllActionsSelected = this.actionList.actionsjson.actionsList.every((item: any) => item.selected);
    this.actionList.isActionsPartiallySelected = this.actionList.isAllActionsSelected == false ? this.actionList.actionsjson?.actionsList?.filter((item: any) => item.selected).length > 0 : false;
    if (this.selectedActionsList.hasOwnProperty(this.actionList?.resource_name)) {
      if (this.actionList.isAllActionsSelected) {
        this.selectedActionsList[this.actionList?.resource_name] = ['*'];
      } else {
        this.selectedActionsList[this.actionList?.resource_name] = this.actionList.actionsjson.actionsList.filter((item: any) => item.selected).map((item: any) => item.action_name);
      }
    }
    this.noResourceSelected = this.resourceList.every(item => !item.isActionsPartiallySelected && !item.isAllActionsSelected);
  }

  selectAllActions(event: any): void {
    const checked = event.target.checked;
    this.actionList.actionsjson.actionsList.forEach((item: any) => {
      item.selected = checked;
    });
    if (checked) {
      this.actionList.isAllActionsSelected = checked;
      this.actionList.isActionsPartiallySelected = false;
    } else {
      this.actionList.isAllActionsSelected = checked;
      this.actionList.isActionsPartiallySelected = checked;
    }
    this.noResourceSelected = this.resourceList.every(item => !item.isActionsPartiallySelected && !item.isAllActionsSelected);
  }

  selectAllResources(event: any): void {
    const checked = event.target.checked;
    this.resourceList.forEach((item: any) => {
      item.isAllActionsSelected = checked;
      item.isActionsPartiallySelected = false;
      item.actionsjson.actionsList.forEach((action: any) => {
        action.selected = checked;
      });
    });
    this.isAllResourcesSelected = checked;
    this.noResourceSelected = this.resourceList.every(item => !item.isActionsPartiallySelected && !item.isAllActionsSelected);
  }
}

