import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import { ConfigurationService } from "src/app/services/configuration.service";
import { AddStoresComponent } from "./add-stores/add-stores.component";
import { CommonService } from "src/app/services/common.service";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { AuthTokenValidationComponent } from "./auth-token-validation/auth-token-validation.component";
import {
  ModalDismissReasons,
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthorizationService } from "src/app/services/authorization.service";
import jwt_decode from "jwt-decode";

@Component({
  selector: "app-stores",
  templateUrl: "./stores.component.html",
  styleUrls: ["./stores.component.scss"],
})
export class StoresComponent implements OnInit {
  @Input() filteredOrgs: any[] = [];

  noDataFound: boolean = false;
  closeResult = "";
  storesData: any;
  totalItems: number = 0;
  pageSize: number = this.configurationService.pageSize;
  itemsPerPage: number = this.configurationService.itemsPerPage;
  currentPage: number = 1;
  paginationId = "store_items";
  defaultTenantId: any = localStorage.getItem("tenantId");
  selectedTenantId: any = "";
  openHours: string = "09:00";
  closeHours: string = "18:00";
  selectedStore: any;
  selectedStoreId: any;
  orgData: any[] = [];
  storeData: any;
  loggedUser: any;
  visibleStore: boolean = false;
  selectedTenantIdDropdown: boolean = false;
  selectedStatus: string = 'Active';
  isSubmitted: boolean;
  message: string = "Initial message";
  confirmationImgIcon = '../../../../assets/icons/confirmationnew.svg';
  private ngUnsubscribe = new Subject<void>();
  isCreateAllowed: boolean;
  decodedToken: any;
  isOrgListAllowed: boolean;

  constructor(
    private configurationService: ConfigurationService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private alertMessagesService: AlertMessagesService,
    private commonService: CommonService,
    private authorizationService: AuthorizationService
  ) {
    this.loggedUser = localStorage.getItem("userRole");
    this.commonService.selectedOrganization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(org => {
      if (org) {
        this.selectedTenantIdDropdown = true;
        this.selectedTenantId = org.tenantId;
      }
    });

    this.commonService.storeTabClicked.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isClicked) => {
      if (isClicked)
        this.getStoresData(this.selectedTenantId);
    });
  }

  ngOnInit(): void {
    this.configByRole();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configByRole() {
    const accessToken = localStorage.getItem("auth_token") ?? "default";
    this.decodedToken = jwt_decode(accessToken);
    this.isCreateAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.stores, ['create', '*']);
    this.isOrgListAllowed = this.authorizationService.isUserAuthorized(this.decodedToken?.policies?.orgs, ['list', '*']);
    if (this.loggedUser === "org-admin") {
      this.selectedTenantId = this.defaultTenantId;
      this.getStoresData(this.selectedTenantId);
    }
  }

  changeOrg(event: any) {
    this.visibleStore = true;
    this.currentPage = 1;
    this.selectedTenantId = event.target.value;
    this.commonService.setSelectedOrganization({
      tenantId: this.selectedTenantId,
      storeId: ''
    });
    this.getStoresData(this.selectedTenantId);
  }

  refreshStoresData() {
    // this.getStoresData(this.selectedTenantId);
  }

  getStoresData(tenantId: any) {
    this.configurationService
      .getStoresData(tenantId, this.pageSize, this.currentPage, this.selectedStatus)
      .subscribe((resp: any) => {
        this.storesData = resp.stores;
        this.storeData = resp.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.totalItems = resp.stores.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }

  changeStore(event: any) {
    this.visibleStore = false;
    this.selectedStoreId = event.target.value;
  }

  onStatusChange() {
    this.configurationService
      .getStoresData(this.selectedTenantId, this.pageSize, this.currentPage, this.selectedStatus)
      .subscribe((resp: any) => {
        this.storesData = resp.stores;
        this.storeData = resp.stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName));
        this.totalItems = resp.stores.length;
        if (this.totalItems === 0) {
          this.noDataFound = true;
        }
      });
  }

  storePageChanged(event: any) {
    this.currentPage = event;
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }

  addStoresData() {
    const modelRef = this.modalService.open(AddStoresComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modelRef.componentInstance.filteredOrgs = this.filteredOrgs;
    modelRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getStoresData(this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  handleActionEvent(actionDetails: any) {
    if (actionDetails.action === 'edit') {
      this.updateStoreData(actionDetails.data);
    } else if (actionDetails.action === 'delete') {
      this.deleteStore(actionDetails.data.storeId);
    } else if (actionDetails.action === 'hyperion') {
      this.validateAuthToken(actionDetails.data);
    }
  }

  updateStoreData(store: any) {
    const modalRef = this.modalService.open(AddStoresComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.storeData = store;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getStoresData(this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteStore(storeId: any) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'alert-warning-container',
        htmlContainer: 'alert-warning-content',
        confirmButton: "btn btn-submit",
        cancelButton: "btn btn-cancel",
        icon: "alert-warning-icon",
        title: "alert-warning-title",
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      iconHtml: `<img src="${this.confirmationImgIcon}"  width="58px" height="58px" alt="" " />`,
      title: "Are you sure?",
      text: "You are really want to delete the Store.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.configurationService.deleteStore(storeId).subscribe(
          (resp: any) => {
            if (resp.status === true) {
              this.alertMessagesService.alertWithSuccess("Store deleted successfully.");
              this.getStoresData(this.selectedTenantId);
            } else {
              this.alertMessagesService.alertWithError("Failed to delete Store");
            }
          },
          (error) => {
            this.alertMessagesService.alertWithError("Failed to delete Store");
          }
        );
      }
    });
  }

  validateAuthToken(store: any) {
    const modalRef = this.modalService.open(AuthTokenValidationComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "add-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.storeData = store;
    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getStoresData(this.selectedTenantId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
